#delivery-container{
  .btn-group{
    display: block;
    padding-bottom: 10px;
    padding-top: 10px;
    .btn-outline-primary{
      border: 1px solid $gray-300;
      min-height: 110px;
      height: 100%;
      display:flex;
      align-items: center;
      justify-content: center;
      color: $black;
      cursor: pointer;
      div{
        white-space: normal;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 0;
        span{
          font-weight:300;
        }
      }
      &:hover, &.active{
        background-color: $white;
        color: $black;
        border-color: theme-color('primary');
        box-shadow: inset 0px 0px 0px 3px theme-color('primary');

      }
      &:nth-child(3n){
        margin-right: 0;
      }
    }
  }
  .adresses-form-group{
    .btn-group{
      .plain-link{
        text-decoration: underline;
        pointer-events: none;
        opacity: 0;
        bottom: 7px;
        right: 7px;
        &.visible{
          pointer-events: initial;
          opacity: 1;
        }
      }
      .btn-outline-primary{
        padding: 30px;
      }
    }

  }
  .form-group.is-invalid ~ .adresses-form-group .btn-group .btn-outline-primary,
  .form-control.is-invalid ~ .row .btn-group .btn-outline-primary{
    border-color: theme-color('danger');
  }
}
