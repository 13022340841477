.alert-danger {
  color: #d40d1b !important;
}

.alert-success {
  color: #509232 !important;
}

.alert-warning {
  color: #fcae00 !important;
}

.alert-info {
  color: #0089f9 !important;
}

.alert-dark {
  color: #7a7a7a !important;
}

.alert-danger {
  background-color: #fdeded !important;
}

.alert-success {
  background-color: #f6fffa !important;
}

.alert-warning {
  background-color: #fdf3e3 !important;
}

.alert-info {
  background-color: rgb(249, 252.3012048193, 255) !important;
}

.alert-dark {
  background-color: #f2f2f2 !important;
}

.fixed-top {
  z-index: 2000 !important;
}

.modal-image .modal-content {
  overflow: hidden;
}
.modal-image header {
  height: 0 !important;
  padding: 0;
  overflow: hidden;
}
.modal-image header button {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10000;
  opacity: 0.5;
}
.modal-image .modal-body {
  padding: 0;
  position: relative;
}
.modal-image .body-image {
  width: 100%;
}
.modal-image .button-container {
  position: absolute;
  bottom: 25%;
  left: 0;
  right: 0;
  text-align: center;
}

.modal-title-empty header {
  border-bottom-width: 0;
}