
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            


@keyframes fade-from-right {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-right-enter-active {
    animation: fade-from-right .5s ease 1;
}

.fade-right-leave-active {
    animation: fade-from-right .5s ease 1 reverse;
}
