.vouchers-form{
  padding-bottom: 3rem;
  h4 {
    margin: 2rem 0 0;
    width: 50%;
    padding-bottom:1rem;
    border-bottom: 1px solid $gray-300;
  }
  
  .vouchers-img{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    label{
      flex: 0 0 49%;
      margin: 0 !important;
      margin-bottom: 2rem !important;
    }
    img{
      border: 1px solid $gray-500;
    }
  }
  .form-field{
    margin-bottom: 15px;
    input{
      width: 50%;
    }
    .btn-group{
      flex-wrap: wrap;
    }
  }
  .vouchers-squares{
    border: 3px solid #bcbcbc;
    color: $black !important;
    font-weight: 700;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    background: $white !important;
    margin-bottom: 3rem !important;
    &:hover, &.active {
      border-color: $red !important;
    }
  }
  .vouchers-button{
    width: 50%;
    float: none !important;
    button {
      width: 100%;
    };
  }
  @include media-breakpoint-down(sm) {
    .form-field input{
      width: 96%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    h4 {
      width: 100%;
      padding-left: 0.5rem;
    }
    .vouchers-button {
      width: 80%;
      margin-left: 0.5rem;
      .btn-primary {
        height: auto;
      }
    }
    .form-field__checkbox {
      margin-left: 0.5rem;
    }
    .vouchers-img label{
        flex: 0 0 97%;
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;

      }
    .vouchers-squares {
      margin-bottom: 2rem !important;
    }
  }
  @include media-breakpoint-down(xs) {
    .vouchers-button {
      width: 96%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
.vouchers-confirm{
  padding-bottom: 3rem;
  button{
    width: 100%!important;
  }
}
