
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.modal-image {
    .modal-content {
        overflow: hidden;
    }
    header {
        height: 0 !important;
        padding: 0;
        overflow: hidden;
        button {
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 10000;
            opacity: 0.5;
        }
    }
    .modal-body {
        padding: 0;
        position: relative;
    }
    .body-image {
        width: 100%;
    }
    .button-container{
        position: absolute;
        bottom: 25%;
        left:0;
        right: 0;
        text-align: center;
    }
}
.modal-title-empty {
    header {
        border-bottom-width: 0;
    }
}
