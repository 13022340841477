.home {
&-banner{
  position: relative;
  display: block;

  border: 0 none !important;

  &__wrapper{
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;

    padding: 3rem;

    display: flex!important;
    align-items: flex-end!important;
    flex-direction: column!important;
  }
  &__content{
    width: 100%;
    height:100%;
    font-size:200%;
    font-weight:100;
    line-height: 150%;

    @include media-breakpoint-down(lg){
      font-size: 2vw;
      line-height: 3vw;
    }

    @include media-breakpoint-down(sm){
      font-size: 4vw;
      line-height: 6vw;
    }



  }
  &__btn{
      margin-top:auto;
      width: 100%;
      text-align: center;
      text-decoration: none !important;
  }
  a {
    &:hover, &:active, &:focus {
      color: initial !important;
    }
  }
}
&-photos {
  
  @include media-breakpoint-down(xs) {
    padding-right: $container-gutter !important;
    padding-left: $container-gutter !important;
  }
  
  a {
    position:absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .embed-responsive-16by9:before {
    @include media-breakpoint-down(sm) {
      padding-top: calc(56.25% * 2);
    }
    
  }
  .height-1on2 {
    height: calc(50% - 10px);
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      height: calc(33.3333% - 13px);
    }
  }
  .width-1on3 {
    width: calc(33.3333% - 13px);
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      width: calc(50% - 10px);
    }
  }
  .left-top {
    left:0;
    top:0;
  }
  .middle-top {
    top:0;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-down(sm) {
     left: calc(50% + 10px);
     transform: none;
    }
  }
  .right-top {
    top:0;
    right:0;
    @include media-breakpoint-down(sm) {
      left: 0;
      right: auto;
      bottom: 0;
      top: auto;
     }
  }
  .left-bottom {
    left:0;
    bottom:0;
    @include media-breakpoint-down(sm) {
      top: 50%;
      transform: translateY(-50%);
      bottom: auto;
     }
  }
  .right-bottom {
    right:0;
    bottom:0;
  }

  .height-2on2 {
    height:100%;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      height: calc(66.6666% - 5px);
     }
  }

}
&-club {
  @include media-breakpoint-down(xs) {
    padding-right: $container-gutter !important;
    padding-left: $container-gutter !important;
  }

  &__wrapper {
    position: relative;
    background-color: theme-color('primary');
    padding-top: calc(100% * 4 / 16);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(md) {
      padding-top: calc(100% * 7 / 16);
    }

    @include media-breakpoint-down(xs) {
      padding-top: calc(100% * 9 / 16);
    }

    @media(max-width: 400px) {
      padding-top: calc(100% * 11 / 16);
    }
  }


  &__content {
    position: absolute;
    top: 50%;
    left: 45%;
    width: 55%;
    transform: translateY(-50%);
    text-align: center;
    padding: rem(8px);

    @include media-breakpoint-down(md) {
      left: 35%;
      width: 65%;
    }
    
    @include media-breakpoint-down(xs) {
      top: 50%;
      left: 0%;
      width: 100%;
      transform: translateY(-50%);
    }
  }
 
  h2 {
    font-size: 2.5rem;
    color: $white;
    font-weight: 700;
    margin-bottom: 1rem;
    display: inline-block;
    @include media-breakpoint-down(md){
      font-size: rem(28px);
      margin-bottom: .75rem;       
    } 
    @include media-breakpoint-down(xs){
      font-size: rem(24px);
    } 
  }

  h3 {
    font-size: 2rem;
    font-weight: 200;
    color: $white;
    font-style: italic;
    margin-bottom: .5rem;
    line-height: 1;
    display: inline-block;

    @include media-breakpoint-down(md){
      font-size: 1.5rem;
    } 
    
    @include media-breakpoint-down(xs){
      font-size: 1.25rem;
    } 
  } 
  p {
    color: $white;
    font-size: rem(16px);
    line-height: 1.15;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(xs){
      font-size: rem(14px);
      margin-bottom: 1rem;
    } 
  }
}
&-instagram {
  padding-bottom: 1rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 -5px;
    overflow: hidden;
  }

  &__list-item {
    width: calc(100% / 7);
    padding: 0 5px 10px 5px ;
   
    @include media-breakpoint-down(lg){
      width: calc(100% / 6);
      
      &:nth-child(7) {
        display:none;
      }
    }

    @include media-breakpoint-down(md){
      width: calc(100% / 4);
     
      &:nth-child(6),
      &:nth-child(5) {
        display:none;
      }
    }

    @include media-breakpoint-down(sm){
      width: calc(100% / 2);
    }
  }
 
  @include media-breakpoint-down(lg){
    .row>div:nth-child(7) {
      display:none;
    }
  }
}
}
.homepage-section-banner-wide{
  .btn{
    @include media-breakpoint-up(sm){
      padding-left: 120px;
      padding-right: 120px;
    }
  }
}
.homepage-section-banner-info, 
.homepage-section-video {
  
  @include media-breakpoint-down(md) {
    padding-right: $container-gutter !important;
    padding-left: $container-gutter !important;
  }

  .home-banner__wrapper {
    padding: 0 16%;
    &>div {
      margin-top:auto;
      margin-bottom: auto;
    }
  }
  .home-banner__content {
    height:auto;
    h2.h1 {
      color:white;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 1;
      @include media-breakpoint-down(sm){
        font-size: 2rem;
      }
      @include media-breakpoint-down(xs){
        font-size: 1.3rem;
      }
    }
    h3 {
      font-size: 2.8rem;
      font-weight: 200;
      color:white;
      font-style: italic;
      margin-bottom:1.5rem;
      line-height:1;
      @include media-breakpoint-down(sm){
        font-size: 2rem;
      }
      @include media-breakpoint-down(xs){
        font-size: 1.3rem;
      }
    }
    .home-banner__title {
      h4.h3 {
        color: #fff;
        font-size: 2.2rem;
        font-weight: 600;
        padding-bottom:30px;
        @include media-breakpoint-down(sm){
          font-size: 1.6rem;
        }
        @include media-breakpoint-down(xs){
          display: none;
        }
      }
      .separator {
        width: 50px;
        height: 3px;
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        @include media-breakpoint-down(xs){
          display: none;
        }
      }
      .play-btn {
        margin-left: auto;
        margin-right: auto;
        color: $white;
        font-size: 2.5rem;
        width: 80px;
        height: 80px;
        background-color: rgba(212, 13, 27, 0.7);
        line-height: 80px;
        @include media-breakpoint-down(xs){
          width: 50px;
          height: 50px;
          font-size: 2rem;
          line-height: 50px;
        }
      }
      &.ios{
        top: 30%;
        .play-btn{
          display: none;
        }
      }
    }
    &:hover{
      .play-btn{
        color: rgba(212, 13, 27, 1);
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  
}

.homepapage-section-banner-half {
  padding: 0 $container-gutter;
  
  @include media-breakpoint-up(sm){
    padding: 0;
  }
}

.banner-double .home-banner__content{
  font-size: 160%;
}
.home-banner__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homepage-section-video {
  cursor: pointer;
  .home-banner__title {
    pointer-events: none;
  }
}
video::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls {
  display:none !important;
}
