.cupboard{
 .fixed-bottom{
    border-top: 1px solid $gray-300;
   button{
     @include media-breakpoint-down(sm){
       padding-left: 15px;
       padding-right: 15px;
     }
   }
 }
 &__item{
   .product-item__footer{
     margin-bottom: 100px;
   }
 }
 &__buttons{
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
 }
}
.wishlist-modal{
  .modal-header{
    justify-content: flex-start;
    padding-bottom: 1rem !important;
    .modal-title{
      padding-left: 35px;
      position: relative;
      &:before{
        content: "\E90C";
        font-family: 'icons' !important;
        position: absolute;
        font-size: 24px;
        width: 30px;
        height: 30px;
        left: 0;
        top: -7px;

      }
    }
  }
  .cupboard-list-container{
    margin: 0 0 1rem 0 !important;
    padding: 0.5rem 0 !important;
    justify-content: flex-start !important;
    text-align: left;
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    #cupboard-list{
      text-transform: uppercase;
      font-weight: 500;
      span{
        font-weight: 300;
      }
    }
  }
  .popover{
    left: 30px !important;
    @include media-breakpoint-down(xs) {
      left: 15px;
    }
    .form-field__radio label{
      text-transform: uppercase;
    }
  }
  .product-preview {
    min-height: 180px;
    .img-container{
      max-width: 110px;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      @include media-breakpoint-down(xs) {
        position: relative;
        margin: 0 auto;
        margin-bottom: 2rem;
      }
    }
  }
  .modal-footer{
    @include media-breakpoint-down(xs) {
      padding-top: 5px !important;
    }
  }
}
