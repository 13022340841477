.alert-danger {
  color: #d40d1b !important;
}

.alert-success {
  color: #509232 !important;
}

.alert-warning {
  color: #fcae00 !important;
}

.alert-info {
  color: #0089f9 !important;
}

.alert-dark {
  color: #7a7a7a !important;
}

.alert-danger {
  background-color: #fdeded !important;
}

.alert-success {
  background-color: #f6fffa !important;
}

.alert-warning {
  background-color: #fdf3e3 !important;
}

.alert-info {
  background-color: rgb(249, 252.3012048193, 255) !important;
}

.alert-dark {
  background-color: #f2f2f2 !important;
}

.fixed-top {
  z-index: 2000 !important;
}

.btn-icon {
  background: transparent;
  border: 0 transparent;
  font-weight: 100;
  outline: none !important;
}
.btn-icon i {
  margin-right: 10px;
  bottom: -3px;
  position: relative;
  font-size: 18px !important;
  color: #000000 !important;
}