.quick-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include media-breakpoint-down(md){
    display: none;
   }
  i {
    transition: 250ms ease-in all;
  }
}

.quick-filter {
  position: relative;
  margin-bottom: 0.5rem;

  .form__checkbox-label:before {
    width: 20px !important;
    height: 20px !important;
  }

  &__trigger {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0;
    margin-right: 2rem;
    border: 0 none;
    background-color: transparent;
    font-size: rem(14px);
    font-weight: 500;
    color: $black;

    &:focus {
      outline: none;
    }

    &--open {
      color: theme-color('primary');
        i {
          transform: rotate(180deg);
          border-top-color: theme-color('primary');
      }
    }
  }

  .quick-filter-dropdown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    background-color: $white;
    transform: translateY(100%);
    z-index: 1000;

    .form-field {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.quick-filter-dropdown {
  padding: 1rem;
  max-height: 300px;
  border: 1px solid $gray-300;
  padding-bottom: 56px;

  &__content {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 220px;
    padding: 0.5rem;
    padding-right: 0;
  }

  &__actions {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    display: flex;
    padding: 0.5rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
