.product-brick{
  height: 100%;
  .product-item {
    background: $white;
    position: relative;
    display: block;
    padding-right: ($grid-gutter-width * 0.5);
    padding-left: ($grid-gutter-width * 0.5);
    margin-bottom: ($grid-gutter-width * 0.5);
    padding-bottom: ($grid-gutter-width * 0.5);
    height: 100%;
    border: 0 !important;

    &__body {
      position: relative;
      display: block;
      z-index: 1000;
      background-position: center center;
      background-color:#f7f7f7;
      background-repeat: no-repeat;
      z-index: 999;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 45 45' style='enable-background:new 0 0 45 45;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill:%23CCCCCC;%7d %3c/style%3e%3cg%3e%3cpath class='st0' d='M39.5,37.7H7.5c-0.9,0-1.7-0.7-1.7-1.7V15.5c0-0.9,0.7-1.7,1.7-1.7h4.9c0.3,0,0.5-0.2,0.6-0.4c0,0,0,0,0,0 l2.1-5.2c0.3-0.6,0.9-1,1.5-1h13.6c0.7,0,1.3,0.4,1.5,1l2.1,5.2c0.1,0.2,0.3,0.4,0.6,0.4h4.9c0.9,0,1.7,0.7,1.7,1.7V36 C41.1,37,40.4,37.7,39.5,37.7z M7.5,14.9c-0.4,0-0.7,0.3-0.7,0.7V36c0,0.4,0.3,0.7,0.7,0.7h31.9c0.4,0,0.7-0.3,0.7-0.7V15.5 c0-0.4-0.3-0.7-0.7-0.7h-4.9c-0.7,0-1.3-0.4-1.5-1l-2.1-5.2c-0.1-0.2-0.3-0.4-0.6-0.4H16.7c-0.3,0-0.5,0.2-0.6,0.4L14,13.9 c-0.3,0.6-0.9,1-1.5,1H7.5z'/%3e%3c/g%3e%3cg%3e%3cpath class='st0' d='M23.5,32.4c-4.9,0-8.9-4-8.9-8.9c0-4.9,4-8.9,8.9-8.9c4.9,0,8.9,4,8.9,8.9C32.4,28.4,28.4,32.4,23.5,32.4z M23.5,15.6c-4.4,0-7.9,3.6-7.9,7.9c0,4.4,3.5,7.9,7.9,7.9s7.9-3.6,7.9-7.9C31.4,19.1,27.9,15.6,23.5,15.6z'/%3e%3c/g%3e%3c/svg%3e");
      background-size: 55%;
    }

    &__badges {
      position: absolute;
      z-index: 1001;

      &-item {
        margin: 0 0 0 ($grid-gutter-width * 0.5);
        display: block;
        font-size: 90%;

        &--text {
          background: $white;
          padding: ($grid-gutter-width * 0.25) ($grid-gutter-width * 0.5);
          text-transform: uppercase;
        }
        &:first-child{
          margin-top: $grid-gutter-width * 0.5;
        }
      }
    }

    &__image {
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;


      &:before {
        content: '';
        padding-top: 150%;
        display: block;
        // pointer-events: none;

      }

      img {        
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
      }

      .product-item__img {
        visibility: hidden;
      }

      &:hover {
        .product-item__img--hidden {
          visibility: visible !important;
        }
      }
    }

    &__preview {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      z-index: 1000;
    }

    &__cupboard {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1000;
    }

    &__footer {
      display: block;
      margin: 15px 0 0 0;
      padding: 0;
      text-transform: none !important;
    }
    &__product-name{
      display: block;
      word-break: break-word;
      text-transform: uppercase;
      line-height: 20px;
    }
    &__producer-name {
      font-size: 0.8rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    &__price{
      s {
        font-weight: $font-weight-normal;
        color:$gray-500;
      }
      font-weight: $font-weight-bold;
    }
    &__colors {
      position:absolute;
      display:none;
      .color-wrapper {
        float:left;
        width:22px;
        height:22px;
        margin: 2px;
        padding: 3px;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid $gray-500;
        }
      }
      .color {
        width:14px;
        height:14px;
        border: 1px solid $gray-300;
        &--image{
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; 
        }
      }
      @include media-breakpoint-down(md){
        position: relative;
        display: block;
      }
    }
    // cień

    &:before {
      position: absolute;
      top: -0.5rem;
      left: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      opacity: 0.5;
      content: '';
      display: block;
      z-index: 100;
      transition: all 0.15s;
    }
    &:hover {
      .product-item__preview {
        display: block !important;
      }
      .product-item__price {
        .price, .currency {
          color: $black;
        }
        s .price, s .currency {
          color: $gray-500
        }
        .text-danger {
          .price, .currency {
            color: theme-color('primary');
          }
        }
      }
      ul{
        color: $black
      }
      .product-item__colors {
        display:block;
      }
    }
  }
}


