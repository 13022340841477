
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.thumb-image {
    max-height: 9.5vh;
    opacity: .8;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}
.thumb-active {
   opacity: 1;
   max-height: 10vh;
}
.pswp__caption__center {
    max-width: none !important;
}
.pswp__ui--hidden .pswp__top-bar,
.pswp__ui--hidden .pswp__caption,
.pswp__ui--hidden .pswp__button--arrow--left,
.pswp__ui--hidden .pswp__button--arrow--right {
  opacity: 1 !important;
}
