.alert-danger {
  color: #d40d1b !important;
}

.alert-success {
  color: #509232 !important;
}

.alert-warning {
  color: #fcae00 !important;
}

.alert-info {
  color: #0089f9 !important;
}

.alert-dark {
  color: #7a7a7a !important;
}

.alert-danger {
  background-color: #fdeded !important;
}

.alert-success {
  background-color: #f6fffa !important;
}

.alert-warning {
  background-color: #fdf3e3 !important;
}

.alert-info {
  background-color: rgb(249, 252.3012048193, 255) !important;
}

.alert-dark {
  background-color: #f2f2f2 !important;
}

.fixed-top {
  z-index: 2000 !important;
}

.basket {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  width: 25vw;
  margin-right: -100%;
  transition: margin 0.7s;
  padding: 5px 10px;
}
@media (max-width: 991px) {
  .basket {
    width: 35vw;
  }
}
@media (max-width: 767px) {
  .basket {
    width: 50vw;
  }
}
@media (max-width: 480px) {
  .basket {
    width: 80vw;
  }
}
.basket-content {
  padding: 0.5rem 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  z-index: 1003;
  overflow: auto;
}
.basket.show {
  margin-right: 0;
}
.basket.show .basket-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1002;
  cursor: pointer;
}
.basket .btn-link {
  padding: 0.2rem;
}