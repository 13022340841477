.lookbook-home {
  &__header {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  &__title {
    margin-bottom: 1rem;
  }
  &__desc {
    margin-bottom: 1rem;
  }
}

.lookbook-home-item {
  background-color: $white;
  display: block;
  border-bottom: none !important;

  &__image-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__title {
    text-align: center;
    padding: 1rem;
    margin-bottom: 0;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    font-size: rem(14px);
    letter-spacing: 0;
    text-transform: none;
  }
}

.lookbook-page {
  &__header {
    text-align: center;
  }
}

.lookbook-item {
  margin-bottom: 2rem;
  &__image-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__header {
    text-align: center;
  }
  &__title {
    margin: 2rem 0 1rem;
    padding: 0 2rem;
    text-align: center;
    text-transform: none;
    color: $gray-600;
    font-family: $font-family-base;
    letter-spacing: 0;
  }
  .btn {
    margin: 0 auto;
    display: inline-block;
  }
}
