.alert-danger {
  color: #d40d1b !important;
}

.alert-success {
  color: #509232 !important;
}

.alert-warning {
  color: #fcae00 !important;
}

.alert-info {
  color: #0089f9 !important;
}

.alert-dark {
  color: #7a7a7a !important;
}

.alert-danger {
  background-color: #fdeded !important;
}

.alert-success {
  background-color: #f6fffa !important;
}

.alert-warning {
  background-color: #fdf3e3 !important;
}

.alert-info {
  background-color: rgb(249, 252.3012048193, 255) !important;
}

.alert-dark {
  background-color: #f2f2f2 !important;
}

.fixed-top {
  z-index: 2000 !important;
}

.product-card__lowest-month-price {
  line-height: 1.5;
  color: black;
  font-size: 11px;
}
@media (max-width: 992px) {
  .product-card__lowest-month-price {
    margin: auto;
  }
}
.product-card__lowest-month-price--italic {
  font-style: italic;
}
.product-card__lowest-month-price-value {
  font-weight: 900;
}