
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.basket {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
    width: 25vw;
    margin-right: -100%;
    transition: margin .7s;
    padding: 5px 10px;
    @media(max-width: 991px){
        width: 35vw;
    }
    @media(max-width: 767px){
        width: 50vw;
    }
    @media(max-width: 480px){
        width: 80vw;
    }

    &-content {
        padding: 0.5rem 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #ffffff;
        z-index: 1003;
        overflow: auto;
    }

    &.show {
        margin-right: 0;

        .basket-overlay {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1002;
            cursor: pointer;
        }
    }

    .btn-link {
        padding: .2rem;
    }
}
