.club{
  h3{
    font-weight: 500;
  }
  .brand-name{
    font-style: italic;
    color: theme-color('primary');
    font-weight: 300;
  }
  .club-login{
    background-color: $black;
    color: $white;
    margin-bottom: 150px;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    .btn-outline-primary{
      border-color: $white;
      color: $white;
      &:hover{
        background-color: $white;
        color: $black;
        border-color: $white;
      }
    }
    img{
      width: 300px;
      height: auto;
      top: 50%;
      transform: translateY(-50%) rotate(6deg);
      position: absolute;
      right: 70px;
      @include media-breakpoint-down(lg) {
        width: 230px;
      }
      @include media-breakpoint-down(md) {
        width: 200px;
        right: 10px;
      }
    }
  }
  .club-knowHow-list{
    li{
      list-style: none;
      margin-bottom: 5px;
      &:before{
        content:'';
        display: inline-block;
        width: 4px;
        height: 4px;
        border: 1px solid transparent;
        border-radius: 50%;
        background-color: theme-color('primary');
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  .club-grafics{
    margin: 80px 10px 30px;
    text-align:center;
    position: relative;
    padding-bottom: 50px;
    i{
      font-size: 200px;
    }
    span{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 90px;
      height: 90px;
      background: theme-color('primary');
      color: $white;
      border: 1px solid transparent;
      border-radius: 50%;
      margin: 0 auto;
      margin-top: -30px;
      line-height: 116px;
      i{
        font-size: 44px;
      }
    }
    @media(max-width: 1660px){
      i{
        font-size: 141px;
      }
      span{
        width: 70px;
        height: 70px;
        line-height: 90px;
        i{
          font-size: 34px
        }
      }
    }
  }
}