@use "sass:math";

$productsPerRow: (
        xxs: 1,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 4
);
$productsPerRowLargeItems: (
        xxs: 2,
        xs: 2,
        sm: 3,
        md: 4,
        lg: 4,
        xl: 5
);

@mixin arrange-product-lis($item) {

  @each $breakpoint in map-keys($grid-breakpoints) {

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {

      width: math.div(1, map-get($item, $breakpoint))*100%;
    }
  }
}

.category {
  &-grid {

    &__sidebar {
      position: sticky;
      position: -webkit-sticky;
      top: 135px;
    }

    @include make-row();

    &__category-name {  
      text-transform: capitalize;
      font-weight: 500;
      margin-top: 28px !important;
    }

    &__subcategories {
      list-style-type: none;
      padding: 0 0 10px;
      max-height: calc(90vh - 150px);
      overflow-Y: auto;
      .subcategory {
        .nav-link {
          padding: 4px 16px 4px 0;
          text-transform: uppercase;
        }
      }
      .active {
        color: theme-color('primary');
      }

    }

    &__item {
      @extend %grid-column;
      padding: 0 !important;
      margin-bottom: 40px;
      @include arrange-product-lis($productsPerRow);
      &.last-brick{
        position: relative;
        min-height: 300px;
        .product-item {
          border: 1px solid transparent !important;
          margin: 0 10px 20px 10px;
          cursor: pointer;
          &:before{
            display: none;
          }
          &__body{
            background:none;
            border: 3px solid $black;
          }
          &__image{
            margin-bottom: 30px;
          }
        }
        .last-brick__text{
          position: absolute;
          top: 50%;
          margin-top: -10px;
          left: 0;
          right: 0;
          text-align: center;
          font-family: 'Josefin Sans', sans-serif;
          text-transform: uppercase;
          font-weight: 700;
          span{
            border-bottom: 3px solid transparent;
          }
        }
        &:hover{
          .last-brick__text span{
            border-bottom: 3px solid $black;
          }
        }

      }
    }

    &--small-items {
      .category-grid__item {
        @include arrange-product-lis($productsPerRowLargeItems)
      }
    }

  }
  &-filters {
    .form-field {
      margin-bottom: 0.5rem;
    }
  }
}

.filters-toggle {
  display: flex;
  justify-content: flex-end;
  @include media-breakpoint-down(sm){
   width: 100%;
  }

  .btn {
    align-items: center;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    padding: 10px;
    white-space: nowrap;
    outline: none;
    &:focus, &:active, &:focus-visible, &:focus:active {
      color: $black !important;
      background: transparent !important;
      border: 1px solid $black !important;
      box-shadow: none !important;
      outline: none !important;
    }
    img {
      margin-left: 10px;
      width: 20px;
      @media (max-width: 320px) {
        margin-left: 0;
      }
    }
    span {
      @media (max-width: 320px) {
        display: none;
      }
    }
  }
}

.category-child{
  &__button{
    position: absolute;
    left: 30px;
    right: 30px;
    text-align: center;
    bottom: 40px;
  }
  img{
    opacity: 0.7;

  }
  &:hover img{
    opacity: 1;
  }
  .img-placeholder{
    min-height: 300px;
    background-position: center center;
    background-color:#f7f7f7;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='-4.313 0 28.313 24' enable-background='new -4.313 0 28.313 24' xml:space='preserve'%3E%3Cpath fill='%23D3D3D3' d='M12.15,19.05c-1.275,0-2.475-0.3-3.45-0.975c-0.975-0.675-1.875-1.5-2.475-2.55 C5.55,14.475,5.25,13.275,5.25,12c0-1.35,0.3-2.55,0.9-3.6c0.6-1.05,1.425-1.95,2.475-2.55c0.975-0.6,2.1-0.9,3.375-0.9 c1.275,0,2.4,0.3,3.45,0.975l2.175-3.6C15.825,1.275,13.95,0.75,12,0.75c-2.025,0-3.9,0.525-5.625,1.575 C4.65,3.3,3.3,4.725,2.25,6.45c-0.975,1.725-1.5,3.6-1.5,5.625c0,2.024,0.525,3.899,1.5,5.625c0.975,1.725,2.4,3.075,4.05,4.05 c1.725,0.975,3.6,1.5,5.625,1.5c2.025,0,3.9-0.525,5.625-1.5l-2.1-3.6C14.475,18.75,13.35,19.05,12.15,19.05z'/%3E%3C/svg%3E");
    background-size: 40%;
  }
}


//
//
//.product-list{
//  @include make-row();
//
//  .product-brick{
//    padding-bottom: 0.5rem;
//
//    &__flags{
//      left: 1.5rem;
//    }
//
//    @extend %grid-column;
//
//    @include arrange-product-lis($productsPerRow)
//  }
//  &--lookbook{
//    .product-brick{
//
//      @include arrange-product-lis($productsPerRow-lookbook)
//
//    }
//  }
//}

.seo-text h1{
  font-size: 14px;
}
.seo-text{
  display: none;
}
.show-seo.seo-text{
  display: block;
  @include media-breakpoint-down(md){
    display: none !important;
  }
}

.remove-filter {
  background-color: #f2f2f2;
  line-height: 22px;
  cursor: pointer;
  i {
    font-size: 12px;
    font-weight: bold;
    padding: 0 5px;
  }
}

.remove-filters-button {
  color: $black;
  font-weight: 500;
}

.filter-bar {
  @include media-breakpoint-down(md){
    display: flex;
    justify-content: space-between;
    padding: 0;
    flex-wrap: wrap;
  }
  .mobile-select {
    align-items: center;
    display: none;
    position: relative;
    @include media-breakpoint-down(md) {
      display: flex;
      width: 50%;
    }
    &__input {
      display: none;
      &:checked {
        & ~ .mobile-select__dropdown {
          display: block;
          opacity: 0;
          animation: fadeIn forwards 300ms;
        }
        & ~ .mobile-select__label {
          color: $red;
          i {
            transform: rotate(180deg);
            transform-origin: center;
          }
        }
        @keyframes fadeIn {
          from {
            opacity: 0
          }
          to {
            opacity: 1;
          }
        }
      }
    }
    & ~ .d-flex {
      @include media-breakpoint-down(md) {
        order: 2;
        width: 50%;
        
      }
    }
    &__label {
      align-items: center;
      color: $black;
      cursor: pointer;
      display: flex;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
      margin-right: 5px;
      word-wrap:anywhere;
      i {
        transition: transform 200ms ease-in;
      }
    }
    &__dropdown {
      display: none;
      background: $white;
      box-shadow: 0 4px 2px -2px rgba($black, 0.15);
      position: absolute;
      top: 100%;
      z-index: 1000;
      border-top: 1px solid $light;
      width: 100vw;
      margin: 0;
      right: 0;
      transform: translateX(50%);
    }
    &__list {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      .subcategory {
        user-select: none;
        text-transform: uppercase;
        .nav-link {
          &:hover {
            &:not(.nav-link--main) {
              background: $warning-bg;
              color: $black;
              text-decoration: underline;
            }
          }
          &--main {
            background: #fdf3e3;
            color: $black;
          }
          &:not(.nav-link--main) {
            padding-left: 2rem;
          }
        }
      &.active {
        background: $warning-bg;
        .nav-link {
          color: $black;
        }
      }
      }
    }
  }
}

.mobile-select__wrapper {
  @include media-breakpoint-down(md) {
    margin: 0 !important;
  }
}

.mobile-filters {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
