.my-account{
  .address-block {
    transition: 200ms all ease-in;
  }
 .address-block .block-active {
   border-color: $red !important;
   box-shadow: inset 0px 0px 0px 3px theme-color('primary');
 }


  .add-address-block {

    i {
      font-size: 45px;
      margin: 20px;

    }
    .border {
      transition: all 200ms ease-in;
    }
    &:hover .border{
      border-color: $red !important;
      box-shadow: inset 0px 0px 0px 3px theme-color('primary');
    }
    .border div{
      display: flex;
      align-self: center;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .table-hover{
    .no-hover{
      background-color: transparent !important;
      // *:not(.badge){
      //   background-color: transparent !important;
      // }
      td,th{
        border: none;
        border-bottom:1px solid $gray-100;
      }
      tr:last-child td, tr:last-child th{
        border: none;
      }
    }
    tr.order-list{
      border-bottom:1px solid $gray-300;
      span{
        margin-top: -5px;
        i{
          vertical-align: sub;
        }
      }
    }
    tr.order-active:hover{
      color: $white;
    }
    .border-xs-none{
      @include media-breakpoint-down(sm) {
        border: none !important;
        padding: 0 0.75rem 5px 0.75rem;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .w-xs-30{
      width: 30%;
    }
    .w-xs-70{
      width: 70%;
    }
    .table-order-products{
      td{
        display: inline-block;
      }
      thead{
        display: none;
      }
      tr{
        border-bottom:1px solid $gray-100;
        margin-bottom: 10px;
      }
    }
  }

  .opinions {
    .border{
      .basket__list-item{
        width: 100%;
        border: none;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        .list-item__prices{
          display: none;
        }
        .btn {
          max-width: 300px;
        }
        .list-item__footer{
          display: none;
        }
      }
      .stars-container{
        vertical-align: middle;
        margin-top: -3px;
      }
    }
    .opinion-content{
      word-break: break-word;
    }
  }
  &-menu{
    h1{
      font-size: 36px;
      @include media-breakpoint-down(sm){
        font-size: 25px;
      }
    }
  }
}