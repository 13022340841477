@use "sass:math";

@mixin category-grid($category-grid) {
  @each $breakpoint, $num in $category-grid {
    @include media-breakpoint-up($breakpoint) {
      @include make-col(math.div($grid-columns, $num), $grid-columns);
    }
  }
}

@mixin product-color-icon() {
  color: $black;
  width: auto;
  height: 30px;
  padding-top: 4px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0.25rem;
  &.badge-promo{
    background-color: theme-color('primary');
    color: $white;
  }
  &.badge-sale{
    background-color: $white;
  }
}

@mixin calc($prop, $val) {
  #{$prop}: -moz-calc(#{$val});
  #{$prop}: -webkit-calc(#{$val}) !important;
  #{$prop}: -o-calc(#{$val});
  #{$prop}: calc(#{$val});
}