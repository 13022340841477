footer.page-footer {
  font-size: 12px;
  font-weight: 400;

  .container.container-small {
    @include media-breakpoint-down(md) {
      max-width: 100% !important;
    }
  }

  .page-footer__main {
    padding-top: 50px;
    padding-bottom: 80px;

    @include media-breakpoint-down(md) {
      padding-top: 0px;
      padding-bottom: 10px;
    }

    .trust-badge {
      img {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
  .page-footer__newsletter {
    padding-top: 40px;
    padding-bottom: 30px;
    border-top: 1px solid $gray-500;

    @include media-breakpoint-up(lg) {
      border-top: none;
    }

    h4 {
      font-size: 20px;
      color: $black;
      font-weight: 700;

      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }

      .accent {
        color: theme-color("primary");
      }
    }

    .footer-nl {
      .form-field {
        input {
          padding-right: 150px;
        }

        button.btn--submit-over {
          width: 150px;
          position: absolute;
          top: 20px;
          right: 0;
          background: transparent;
          color: $black;
          font-weight: 700;
          text-transform: none;
          padding-left: 1rem;
          padding-right: 1rem;

          &:focus,
          &:active {
            color: theme-color("primary");
          }
        }
      }
    }
  }
  .page-footer__bottom {
    @include media-breakpoint-down(md) {
      color: $body-color;
      background-color: $gray-100;
    }
  }

  h4 {
    font-weight: 400;
    font-size: 24px;
    text-transform: none;
  }

  .footer-address,
  .phone-contact {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .nav.social-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem 0;
    @include media-breakpoint-down(md) {
      justify-content: center;
      margin: 2rem 0 2rem;
    }
    .nav-item {
      margin-left: 1.2rem;
      &:first-child {
        margin-left: 0;
      }

      @include media-breakpoint-down(md) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        &:first-child {
          margin-left: 0.5rem;
        }
      }
    }
    a {
      display: block;
      border: 0 none;
      line-height: 1;
      transition: color 0.25s;
      color: $black;

      &:hover,
      &:focus,
      &:active {
        border: 0 none;
        color: color-theme("primary");
      }
      i {
        font-size: 1.2rem;
      }
      .i-youtube {
        font-size: 1.5rem;
      }
    }
  }
  .nav-item a {
    transition: color 0.25s;
    &:hover,
    &:active,
    &:focus {
      color: color-theme("primary");
    }
  }
  .mail-contact,
  .phone-contact {
    span,
    a {
      transition: color 0.25s;
      &:hover,
      &:active,
      &:focus {
        color: color-theme("primary");
      }
    }
  }
}

.lang-nav {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 1rem;
  }

  li {
    padding: 5px;
    &:after {
      content: "/";
      padding-left: 10px;
      font-weight: 400;
    }
    &:last-child:after {
      display: none;
    }
  }
  a {
    color: $black;
    border-bottom: 2px solid transparent;
  }
  .active a {
    color: $black;
    border-bottom: 2px solid theme-color("primary");
  }
}

.footer-menu {
  flex-direction: column;
  max-height: none;

  .nav-item {
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }

  a {
    transition: color 0.25s;
    &:hover,
    &:active,
    &:focus {
      color: color-theme("primary");
    }
  }

  @include media-breakpoint-down(md) {
    position: relative;
    border-bottom: 1px solid $gray-300;
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out 0.2s;
    text-align: center;

    > * {
      opacity: 0;
      transition: opacity 0.3s ease-in-out 0s;
    }

    &.show {
      transition: max-height 0.3s ease-in-out 0s;

      > * {
        opacity: 1;
        transition: opacity 0.3s ease-in-out 0.2s;
      }
    }

    .nav-item {
      margin: 0 auto;

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  &__title {
    padding-bottom: 2.5rem;
    margin: 0;

    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
      margin-top: 1rem;
      width: 100%;
      position: relative;

      span {
      }
    }

    .i-arrow-down-small {
      display: none;
      transition: transform .3s;
      transform: translate(-100%, calc(-50% - 0.5rem)) rotate(270deg);
      @include media-breakpoint-down(md) {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
      }
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    &.footer-menu-trigger {
      @include media-breakpoint-down(md) {
        cursor: pointer;
        &.active {
          .i-arrow-down-small {
            transform: translate(-100%, calc(-50% - 0.5rem)) rotate(360deg);
          }
        }
      }
    }

    &--right {
      text-align: right;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    &--spaced-top {
      margin-top: 1rem;
    }
  }
}

.footer-copy {
  margin-top: 1rem;
  font-size: 12px;
  color: $black;
  text-align: center;
}
