
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.params-warning {
    .not-set {
        border-top: 1px solid red;
        color: red;
    }
}

.prod-attr {
    &__popover {
        &-content {
            transition: .5s;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(calc(-100% - 14px));
            padding: 0.5rem 0.75rem;
        }
        &-trigger:not(:hover) + &-content {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
        }
    }
}
