
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.product-card {
  &__lowest-month-price {
    line-height: 1.5;
    color: black;
    font-size: 11px;
    @media(max-width: 992px) {
      margin: auto;
    }
    &--italic {
      font-style: italic;
    }
    &-value {
      font-weight: 900;
    }
  }
}
