.scroll-box{
  height: 250px;
}
.price-filter{
  display: flex;
  justify-content: space-between;
  &__select {
    width: 40%;
    padding: 0.8rem 0.9rem;
  }
  &__separator{
        display: inline-block;
    line-height: 44px;
  }
}
.color-filter .form__radio {
  float: left;
  &--color {
    border-radius: 100%;
    display: inline-block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    left: 3px;
    margin-right: 1em;
    pointer-events: none;

  }
}
.toggle-view-btn{
  display: inline-block;
  width: 26px;
  height: 27px;
  text-align: center;
  line-height: 40px;
  font-size: 25px;
  color: $gray-500;
  margin: 0 2px;
  @media(max-width:1659px){
    margin:0;
  }
  &.active, &:hover{
    color: $black;
  }
  i{
    vertical-align: middle;
  }
}
.filters-container{
  position: fixed;
  width: 80%;
  max-width: 550px;
  overflow-y: auto ;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
  transition: transform .3s ease-in;

  @include media-breakpoint-down(sm){
    width: 100%;
    max-width: 100%;
  }
  
  &.show {
    visibility: visible;
    opacity: 1;
    pointer-events: all;  
    transform: translateX(0);
    transition: transform .3s ease-in;
  }
}

.filters-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1002;
  cursor: pointer;
  
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in, visibility 0s ease-in .05s;
  
  &.show {
    visibility: visible;
    opacity: 1;
    pointer-events: all;  
    transition: opacity .3s ease-in, visibility 0s 0s;
  }
}

.filters {
  z-index: 1003;
  position: relative;
  background: #ffffff;
}

.filters-fishes-list {
  padding: 2rem 1rem 1rem;
}

.filters{
  overflow-y: auto;
  height: 100%;
  padding-bottom: 70px !important;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  &__title {
    padding: 1rem;
    border-bottom: 1px solid $gray-500;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h3.h5 {
      text-transform: none;  
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
    }

    a {
      line-height: 1;
    }

    i.i-close {
      font-size: 18px;
    }
  }

  &__actions {
    border-top: 1px solid $gray-300;
    background-color: white;
    position: fixed;
    width: 100%;
    max-width: 550px;
    bottom: 0;
    display: flex; 
    padding: 1rem;

    @include media-breakpoint-down(sm){
      width: 100%;
      max-width: 100%;
    }
  }

  .filters-collapse {
    border-bottom: 1px solid $gray-300;

    &__toggle{
      position: relative;
      display: block;
      width: 100%;
      padding: 1rem;
      margin-bottom: 0;
      text-align: left;
      background-color: transparent;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: rem(12px);
      line-height: 1.5;
      
      &:focus {
        outline: none;
      }


      span.custom-arrow {
        position: absolute;
        right: 1rem;
        top: 50%;
        height: auto;
        padding: 0;
        transform: translate(-50%, -50%);
        
        i {
          transform: rotate(-180deg);
          transition: transform .25s ease-in;
        }
      }
      
      &.collapsed {
        span.custom-arrow {
          i {
            transform: rotateZ(0deg);
          }
        }
      }
    }
    &__body {
      padding: 0 1rem;

      .list-unstyled {
        padding: 1rem 0;
      }

      .form-field {
        margin-bottom: .75rem;
      }
    }
  }

  .form__checkbox-label{
    padding-left: 30px;

    &:before{
      position:absolute !important;
      left: 0;
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.sort-select{
  .vs--open .vs__selected{
    display: none !important;
  }
  .form-field__placeholder {
    top: -1px;
  }
}
.filters-open{
  overflow: hidden;
}

// Firefox fix #72575
@supports (-moz-appearance:none) {
  .filters-container {
    .filters__partial {
      &:nth-last-child(2) {
        margin-bottom: 70px;
      }
    }
  }
}