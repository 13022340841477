.breadcrumb {
  background-color: transparent;
  padding: 0 0.5rem;
  margin-bottom: 0;
  flex-wrap: nowrap;
  min-height: 28px;
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    text-transform: uppercase;
    height: 28px;
    line-height: 30px;
    display: inline-block;
    flex-wrap: nowrap;
    a {
      white-space: nowrap;
    }
  }
  .breadcrumb-item {
    padding-left: 0;
    &:after {
      content: "/";
      padding-left: 5px;
      padding-right: 6px;
    }
  }
  .breadcrumb-item:last-child:after,
  .breadcrumb-item:before {
    display: none;
  }
  a.disabled {
    border: none !important;
    pointer-events: none;
    cursor: default;
    color: $gray-300 !important;
  }
  .active {
    @include media-breakpoint-up(sm) {
      white-space: nowrap;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.basket__breadcrumb {
    padding: 0;
    white-space: nowrap;
    .breadcrumb-item {
      width: 33.333333%;
      text-align: center;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      @include media-breakpoint-down(sm) {
        width: auto;
        span {
          padding: 0 5px !important;
          &:before {
            content: "—";
            font-weight: normal;
            padding-right: 10px;
          }
        }

        &.active {
          span {
            color: $black;
          }
        }
      }
      span {
        display: inline-block;
        padding: 0 15%;
        background-color: $white;
      }
      &:after {
        display: none;
      }
    }
    .breadcrumb-item:first-child {
      text-align: left;
      span {
        padding-left: 0 !important;
        &:before {
          display: none;
        }
      }
    }
    .breadcrumb-item:last-child {
      text-align: right;
      span {
        padding-right: 0 !important;
      }
    }
  }
}
.basket__breadcrumb--line {
  border-top: 1px solid $gray-300;
  margin-top: -14px;
}
.breadcrumb:not(.basket__breadcrumb) {
  @include media-breakpoint-down(sm) {
    display: block;
    li {
      a {
        margin-right: 7px;
        margin-left: 7px;
      }
    }
  }
  .breadcrumb-item {
    &:not(.active) {
      span {
        color: #7a7a7a;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      display: inline;
      &:first-child {
        a {
          margin-left: 0;
        }
      }
      &:after {
        padding-left: 0;
        padding-right: 0;
      }
      a {
        white-space: normal;
      }
      span {
        text-transform: none;
      }
    }
  }
}
