body.menu-show{
  @include media-breakpoint-down(md) {
    overflow: hidden;
    .header-wrapper {
      background: $white;
    }
  }
}

.header{
  &-nav:not(.header-nav--basic){
    @include media-breakpoint-up(lg) {
      flex: 0 0 40%;
    }
    @include media-breakpoint-down(md) {
      margin-left: -50px;
    }
    // .main-menu-bg{
    //   position:fixed;
    //   left: 0;
    //   right: 0;
    //   top: 54px;
    //   height: 0;
    //   background: $white;
    //   z-index: 9;
    //   transition: height 0.15s linear;
    //   transition-delay: 0.35s;
    //   &:after{
    //     position: absolute;
    //     content: '';
    //     left: 0;
    //     right: 0;
    //     top: 100%;
    //     height: 0;
    //     opacity: 0;
    //     background: rgba(0, 0, 0, 0.1);
    //     pointer-events: none;
    //     transition: opacity 0.01s, height 0.01s;
    //     transition-delay: 0.35s;
    //   }
    // }
    .nav{
      @include media-breakpoint-up(lg) {
        display: inline-block;
        &:hover + .main-menu-bg{
          //height: 200px;
          //&:after{
          //  height: 100vh;
          //  opacity: 1;
          //}
        }
        .nav-item{
          float: left;
          padding-bottom:0;
          &.container-auto{
            position: relative;
            .sub-menu{
              position: absolute;
              top: 100% !important;
              transform: translateY(1px);
              right: auto;
              width: auto;
            }
            .submenu-column{
              width: auto !important;
              img{
                max-width: 200px;
              }
            }
          }

        }
      }
    }
    .nav-link{
      padding-bottom: 0;
      text-transform: uppercase;
      line-height: 10px;
      @include media-breakpoint-up(lg) {
        // padding-top: 8px;
      }
    }
    .submenu-list {
      list-style: none;
      overflow-x: hidden;
      @include media-breakpoint-up(lg) {
        margin-top: -16px;
      }

      .list-nav-item.list-nav-item-header{
        font-weight: bold;
        @include media-breakpoint-up(lg) {
          margin-bottom: 10px;
          margin-top: 16px;
        }
      }
      
      .list-nav-item--for-touch {
        display: none;
          @include media-breakpoint-up(lg) {
            &.visible {
              display: block;
            }
          }
      }
    }

    .nav-item{
      padding-bottom: 10px;
      @include media-breakpoint-up(lg) {
        .sub-menu {
          position: fixed;
          background: $white;
          box-shadow: 0 3px 5px rgba($black, .15);
          left: 0;
          display: block;
          right: 0;
          opacity: 0;
          // top: 50px; ustalanie dynamicznie js
          width: 100%;
          height: auto;
          margin-top: -200vh;
          text-transform: uppercase;
          transition: opacity 0.35s;
          transition-delay: 0.35s;
          z-index: 1;

          > .container{
            margin-top: 0px;
            margin-bottom: 0px;
            opacity: 0;
            transition: opacity 0.15s;
            transition-delay: 0.35s;
            &:after{
              content: '';
              display: block;
              height: 1px;
              clear: both;
            }
          }


          .submenu-column {
            &:first-child {
              padding-left: 0 !important;
            }
            &.column-width-1{
              width: 100%;
            }
            &.column-width-2{
              width: 50%;
            }
            &.column-width-3{
              width: 33.333333%;
            }
            &.column-width-4{
              width: 25%;
            }
            &.column-width-5{
              width: 20%;
            }
            &.column-width-6{
              width: 16.666666%;
            }
          }
        }
        &.submenu-open{

          .sub-menu{
            margin-top: -1px;
            opacity: 1;
            > .container{
              opacity: 1;
            }
          }
          a.nav-link span{
           
          }
        }
        &:hover {
          a.nav-link span{
          }
        }
      }
    }
    .nav.log-menu{
      @include media-breakpoint-down(md){
        margin-top: 0px;
        margin-right: 0 !important;
        .nav-item{
          width: 100%;
          padding-left: 0;
          a{
            padding-left: 0;
          }
        }
      }
    }
    .nav:not(.log-menu){
      flex-wrap: nowrap;
      z-index: 10;
      position: relative;
      .menu-overlay{
        display: none;
      }
      @include media-breakpoint-down(md){
        position:fixed;
        top: 0;
        left: 0;
        height: 100%;
        min-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1002;
        margin-left: -100%;
        background: $white;
        transition: margin 0.25s ease-in;
        padding: 5px 0px;
        width: 250px;
        display: block !important;
        padding-top: 60px;
        &.show{
          margin-left: 0;
        }
        .nav-item{
          padding: 0;
          border-bottom: 1px solid $gray-300;
          position: relative;
          &:nth-child(2){
            border-top: 1px solid $gray-300;
          }
          &.show{
            .sub-menu{
              margin-left: 0;
            }
            .submenu-column{
              .submenu-list{
                margin-left: 0;
              }
            }
          }
        }
        &.show ~ .menu-overlay{
          display: block;
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: -100px;
          z-index: 1001;
          cursor: pointer;
        }
        .sub-menu,.submenu-list{
          position:fixed;
          top: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          min-height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 1002;
          margin-left: -100%;
          transition: margin .5s ease-in ;
          background: $white !important;
          width: 250px;
          text-transform: uppercase;
          padding: 60px 0px 20px 0px !important;
        }
        .sub-menu {
          .submenu-column {
            width: 100%;
            float: none !important;
            padding: 0 !important;
            .sub-nav-item {
              display: inline-block;
              padding-bottom: 0 !important;
              padding-top: 10px;
              position: relative;
            }
            a:hover {}
            .submenu-list{
              position: relative;
              padding: 0 !important;
            }
            .list-nav-item{
              border-bottom: 1px solid $gray-300;
              padding: 0 24px !important;
              
              a{
                padding-top: 14px;
              }
            }
            &:first-child .list-nav-item:first-child{
              border-top: 1px solid $gray-300;
            }
          }
          .container-fluid {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .submenu-list{
          .list-nav-item{
            min-height: 45px;
            padding-top: 5px;
          }
        }
        .menu-arrow-next, .menu-arrow-prev{
          cursor: pointer;
          background-color: $gray-100;
          display: inline-block;
          width: 60px;
          height: 41px;
          position: absolute;
          top: 0;
          right: 0;
          border-left: 1px solid $gray-300;
          border-bottom: 1px solid $gray-300;
          text-align: center;
          line-height: 41px;
          i{
            font-size: 18px;
            font-weight: bold;
          }
        }
        .menu-arrow-prev{
          position: absolute;
          top: -2px;
          left: 0;
          border: none;
          width: 70px;
          height: 62px;
          line-height: 70px;
        }
        .nav-item{
          padding-bottom: 0;
          a{
            display: inline-block;
            line-height: 16px;
            padding-bottom: 12px;
            padding-top: 12px;
          }
        }
      }
    }
    .mobile-menu-button{
      font-size: 28px;
      margin-top: 8px;
      cursor: pointer;
      position: absolute;
      display: inline-block;
      left: 10px;
      top: -45px;
      color: $gray-900;
      @include media-breakpoint-down(xs){
        top: -43px;
      }
    }
    .menu-close{
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      top:10px;
      right: 20px;
      z-index: 1003;
    }
  }
}
// .scrolled-down{
//   .main-menu-bg{
//     top: 39px !important;
//   }
// }

.header{
  &-nav{
    &--basic {
    @include media-breakpoint-up(lg) {
      flex: 0 0 40%;
    }
    @include media-breakpoint-down(md) {
      margin-left: -50px;
    }
    .main-menu-bg{
      position:fixed;
      left: 0;
      right: 0;
      top: $page-header-height;
      height: 0;
      background: transparent; // ukrycie elementu  #72477
      z-index: 9;
      transition: height 0s;
      transition-delay: 0.25s;
      &:after{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 100%;
        height: 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;
        transition: opacity 0.01s, height 0s;
        transition-delay: 0.25s;
      }
    }
    .nav{
      @include media-breakpoint-up(lg) {
        display: inline-block;
        &:not(.no-overlay):hover + .main-menu-bg{
          height: 0; // ukrycie elementu  #72477
          &:after{
            height: 100vh;
            opacity: 1;
          }
        }
        .nav-item{
          float: left;
          padding-bottom:0;
        }
      }
    }
    .nav-link{
      padding-bottom: 0;
      padding-top: 15px;
      text-transform: uppercase;
      line-height: 10px;
      @include media-breakpoint-up(lg) {
        padding-top: 8px;
      }
    }
    .submenu-list {
      list-style: none;
      .list-nav-link{
        text-transform: capitalize;
        line-height: 16px;
        margin-bottom: 10px;
      }
    }
    .nav-item{
      padding-bottom: 10px;
      @include media-breakpoint-up(lg) {
        .sub-menu {
          position: fixed;
          left: 0;
          display: block;
          right: 0;
          top: $page-header-height;
          width: 100%;
          height: auto;
          margin-top: -200vh;
          text-transform: uppercase;
          transition: margin-top;
          transition-delay: 0.25s;
          background-color: $white;
          > .container{
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            opacity: 0;
            transition: opacity 0.25s;
            padding: 0 10px;
            &:after{
              content: '';
              display: block;
              height: 1px;
              clear: both;
            }
            .sub-nav-item{
              text-transform: capitalize;
              margin-bottom: 10px;
            }
            &.small-menu-container{
              .no-subCategory{
                width: 100%;
              }
            }
          }


          .submenu-column {
            width: auto;
            padding-right: 5% !important;
            margin-bottom: 2rem;
            &.no-subCategory{
              height: 30px;
              width: 220px;
              line-height: 12px;
              margin-top: 6px;
            }
            &.double-column{
              width: 320px;
              padding-right: 10px !important;
              .list-nav-item{
                width: 50%;
                display: inline-block;
                float: left;

                padding-right: 15px;
              }
            }
          }
        }
        &:hover{
          a.nav-link{
            color: theme-color('primary');
          }
          .sub-menu{
            margin-top: 0;
            > .container{
              opacity: 1;
            }
          }
        }
      }
    }
    .nav.log-menu{
      @include media-breakpoint-down(md){
        margin-top: 20px;
        .nav-item{
          width: 100%;
        }
      }
    }
    .nav:not(.log-menu){
      flex-wrap: nowrap;
      z-index: 10;
      position: relative;
      .menu-overlay{
        display: none;
      }
      @include media-breakpoint-down(md){
        position:fixed;
        top: 0;
        left: 0;
        bottom: -100px;
        z-index: 1002;
        margin-left: -100%;
        background: $white;
        transition: margin 0.15s ease-in;
        padding: 0px 10px;
        width: 250px;
        display: block !important;
        padding-top: 60px;
        &.show{
          margin-left: 0;
          padding-left: 0;
          padding-right: 0;
        }
        .nav-item{
          border-bottom: 1px solid $gray-300;
          padding-right: 50px;
          position: relative;
        }
        .nav-item:nth-child(2){
          border-top: 1px solid $gray-300;
        }
        .nav-item.show{
          .sub-menu{
            margin-left: 0;
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
        .submenu-column.show{
          .submenu-list{
            margin-left: 0;
          }
        }
        &.show ~ .menu-overlay{
          display: block;
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: -100px;
          z-index: 1001;
          cursor: pointer;
        }
        .sub-menu,.submenu-list{
          position:fixed;
          top: 0;
          left: 0;
          bottom: 0;
          z-index: 1002;
          margin-left: -100%;
          transition: margin .5s ease-in ;
          background: $white;
          width: 250px;
          text-transform: uppercase;
          padding: 60px 10px 20px 10px !important;
        }
        .sub-menu {
          .submenu-column {
            width: 100%;
            float: none !important;
            padding: 0 !important;
            position: relative;
            padding-right: 50px;
            border-bottom: 1px solid $gray-300;
            .sub-nav-item {
              display: block;
              padding-bottom: 0 !important;
            }
            &:first-child{
              border-top: 1px solid $gray-300;
            }
          }
        }
        .submenu-list{
          padding-left: 0 !important;
          padding-right: 0 !important;
          .list-nav-item{
            height: 41px;
            border-bottom: 1px solid $gray-300;
            padding-top: 0 !important;
            line-height: 42px;
            padding-left: 28px;
            &:nth-child(2){
              border-top: 1px solid $gray-300;
            }
            a{
              text-transform: uppercase;
            }
          }

        }
        .menu-arrow-next, .menu-arrow-prev{
          cursor: pointer;
          background-color: $gray-100;
          display: inline-block;
          width: 60px;
          height: 41px;
          position: absolute;
          top: 0;
          right: 0;
          border-left: 1px solid $gray-300;
          border-bottom: 1px solid $gray-300;
          text-align: center;
          line-height: 45px;
          i{
            font-size: 18px;
            font-weight: bold;
          }
        }
        .menu-arrow-prev{
          position: absolute;
          top: -2px;
          left: 0px;
          border: none;
          width: 70px;
          height: 62px;
          line-height: 70px;

        }
        .nav-item{
          padding-bottom: 0;
          a{
            display: inline-block;
            line-height: 16px;
            padding-bottom: 12px;
            padding-top: 12px;
          }
        }
      }
    }
    .mobile-menu-button{
      font-size: 28px;
      margin-top: 8px;
      cursor: pointer;
      position: absolute;
      display: inline-block;
      left: 10px;
      top: -45px;
      color: $gray-900;
      @include media-breakpoint-down(xs){
        top: -43px;
      }
    }
    .menu-close{
      cursor: pointer;
      position: absolute;
      top:20px;
      right: 20px;
      z-index: 1003;
      i{
        font-size: 18px;
        font-weight: bold;
      }
    }
    }
  }
}

