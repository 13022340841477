.order-details{
  .table-order-products{
    tbody tr:first-child td {
      border: none;

    }
  }
  .mobile-attr{
    div:not(:last-child){
      border-bottom: 1px solid $gray-300;
    }
    @include media-breakpoint-down(xs) {
      div {
        display: inline-block;
        width: 32%;
        border-bottom: none !important;
        border-right: 1px solid $gray-300;
      }
      div:last-child{
        border-right: none;
      }
    }
  }

  ul.list-unstyled {

    li,
    label {
      line-height: 1rem;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &.summary-total-cost {
        background-color: #d1d1d1;

        .price,
        .currency {
          font-size: rem(14px);
        }
      }
    }
  }

  .order-summary {
    &__products{}
    &__delivery{}
   
    &__statuses {
      p {
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
        .status-value{
            align-self: flex-end;
            text-align: right;
        }
        
        &:not(:last-child) {
          border-bottom: 1px solid $gray-300;
        }         
      }
    }
    &__download{
      .download-list {
        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
    
          b {
            display: inline-block;
    
            @include media-breakpoint-down(md) {
              justify-self: flex-end;
              display: inline-block;
              margin-left: auto;
              padding-left: .5rem;
            }
          }
        }
      }
    }
  }

  .invoice-data,
  .delivery-data {
    &__details {
      line-height: 1rem;
    }
  }
}
.purchase-container{
  margin: 150px 0;
  @include media-breakpoint-down(sm){
    margin: 30px 0;
  }

}
