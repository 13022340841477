.close{
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  content: "\e905";
  font-size: 32px;

  padding: 0.33rem 1.25rem !important;
  text-shadow: transparent 0 0 0;
}