.table{
  thead th{
    border-bottom-width: 1px;
    border-top-width: 0px;
  }
  th{
    font-weight: 500;
  }
  .order-active{
    color: $white;
    .custom-arrow{
      i{
        border-top-color: $white;
        transform: rotate(180deg);
      }
    }
  }
  &.table-nohover{
    tr:hover{
      background-color: initial !important;
    }
  }
}
.txt {
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #222222
}

.txt h2 {
  font-family: proxima_nova_semibold;
  font-size: 16px;
  margin-bottom: 26px
}

.txt p {
  margin: 10px 0
}

.txt ul {
  list-style: initial;
  list-style-position: inside;
  padding-left: 17px
}

.txt li {
  list-style: disc
}

.txt strong {
  font-weight: bold
}

.txt tr:nth-child(even) {
  background: #eee
}

.txt tr:nth-child(odd) {
  background: #fff
}

.txt th {
  text-align: left;
  padding: 5px;
  font-size: 12px;
  color: #fff;
  background-color: theme-color('primary');
}
.txt td {
  padding: 5px;
  white-space: nowrap;
}