.VueCarousel {
  // .VueCarousel-wrapper{
  //   margin: -15px -10px;
  //   padding: 15px 10px;
  // }
  &:hover {
    .VueCarousel-navigation button {
      opacity: 1;
    }
  }
  &-slide{
    // @include media-breakpoint-up(xl){
    //   padding: .5rem;
    // }
  }

  .VueCarousel-navigation {

    button {
      font-family: 'icons' !important;
      speak: none;
      font-style: normal;
      font-weight: 300;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      opacity: 0;
      transition: opacity .25s;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      font-size: 60px;
      color: $black;
      @media(max-width: 1660px) and (min-width: 1200px){
        font-size: 40px;
      }

      @include media-breakpoint-down(sm){
        opacity: 1;
      }

      @media(max-width: 480px){
        font-size: 40px;
      }

      &.VueCarousel-navigation-next {
        right: 70px !important;
        @media(max-width: 480px){
          right: 48px !important;
        }

        &:before {
          content: "\e902";
        }
      }
      &.VueCarousel-navigation-prev {
        left: 70px !important;
        @media(max-width: 480px){
          left: 48px !important;
        }

        &:before {
          content: "\e901";
        }
      }
      &.VueCarousel-navigation--disabled{
        color:$gray-500;
      }
    }

  }
}