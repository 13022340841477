.product-card {

  .product-navigation {
    i {
      font-size: 30px;
    }
  }
  .product-photo {
    background: $white;
    box-sizing: border-box;
    position: relative;
    .img-placeholder{
      position: relative;
      width: 100%;
      display: block;
      padding-top: 150%;
      height: 0;
      background-position: center center;
      background-color:#f7f7f7;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='-4.313 0 28.313 24' enable-background='new -4.313 0 28.313 24' xml:space='preserve'%3E%3Cpath fill='%23D3D3D3' d='M12.15,19.05c-1.275,0-2.475-0.3-3.45-0.975c-0.975-0.675-1.875-1.5-2.475-2.55 C5.55,14.475,5.25,13.275,5.25,12c0-1.35,0.3-2.55,0.9-3.6c0.6-1.05,1.425-1.95,2.475-2.55c0.975-0.6,2.1-0.9,3.375-0.9 c1.275,0,2.4,0.3,3.45,0.975l2.175-3.6C15.825,1.275,13.95,0.75,12,0.75c-2.025,0-3.9,0.525-5.625,1.575 C4.65,3.3,3.3,4.725,2.25,6.45c-0.975,1.725-1.5,3.6-1.5,5.625c0,2.024,0.525,3.899,1.5,5.625c0.975,1.725,2.4,3.075,4.05,4.05 c1.725,0.975,3.6,1.5,5.625,1.5c2.025,0,3.9-0.525,5.625-1.5l-2.1-3.6C14.475,18.75,13.35,19.05,12.15,19.05z'/%3E%3C/svg%3E");
      background-size: 40%;
      @include media-breakpoint-down(sm){
        max-width: 300px;
        padding-top: 80%;
        margin: auto;
        margin-bottom: 15px;
      }
    }
  }
  h1 {
    font-size: 24px;
    word-break: break-word;
  }
  .prices {
    font-size: 2rem;
    min-height: 35px;
    display: inline-block;

    .price-procent {
      font-size: 0.75rem;
      font-style: italic;
      vertical-align: text-top;
    }
    s {
      margin-right: 15px;
      font-size: 1rem;
      color: $gray-500;
      font-weight: 400;
    }
  }

  .product-params-collapse {
    text-align: left;
    padding: 0.5rem 0;

    a {
      color: $black;
    }

    &__title {
      
      cursor: pointer;
      display: block;
      font-weight: normal;
      padding: 1px 30px 5px 0;
      position: relative;
      text-decoration: none !important;

      &.collapsed {
        .toggle-arrow i {
          transform: rotate(0deg);
        }
      }
      .toggle-arrow {
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 3px;
        right: 14px;
        padding: 6px 8px;
        height: 15px;
        cursor: pointer;
      }
      .toggle-arrow i {
        color: $black;
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 0;
        transition: .25s all;
        transform: rotate(180deg);
      }

    }

    &__body {

      > div, ul {
        padding-top: 0.5rem
      }

      p {
        margin-bottom: 0.5rem;
      }
    }

  }
  &__mobile-tabs {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  &__tabs {
    list-style-type: none;
    display: flex;
    position: relative;
    padding: 0;
    &::after {
      content: "";
      border-top: 1px solid $gray-300;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .btn-secondary {
      border: none;
      font-size: 20px;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      text-transform: capitalize;
      padding: 0 !important;
      &:focus, &:active, &:focus-visible, &:focus:active {
        color: $black !important;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
    .tabs__tab {
      &:not(:first-child) {
        margin-left: 60px;
      }
      &.selected {
        .btn-secondary {
          color: #de5d71 !important;
        }
      }
    }
  }

  &__producer {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    .producer-name {
      font-weight: 600;
      margin-bottom: 16px;
      margin-right: 20px;
      font-size: 0.75rem;
      &--underlined {
        text-decoration: underline;
        @include media-breakpoint-down(sm) {
          text-decoration: none;
        }
      }
    }
    .producer-word {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .producer-img {
      max-height: 100px;
      max-width: 125px;
      &__wrapper {
        align-items: flex-start;
        display: flex;
      }

    }
    &--inner {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 125px)
    }
  }

  .tab-content {
    &__read-more-button {
      background: none;
      border: none;
      color: inherit;
      font-weight: bold;
      outline: none;
      padding: 0;
      &:hover {
        text-decoration: underline;
      }
      .read-less {
        display: none;
      }
      span {
        &:first-child {
          display: block;
        }
        &:last-child {
          display: none;
        }
      }
      &.show-last {
        span {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: block;
          }
        }
      }
    }
    .shorter {
      overflow: hidden;
      max-height: 120px;
    }
  }

  .product-description-feature {
    margin: 0;
    padding: 0;

    li {
      list-style-position: inside;
      margin: 0;
      padding: 0;
    }
  }

  .hide-arrows::-webkit-outer-spin-button,
  .hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .prod-attr {
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;

    li {
      margin: 0 0.2rem;
      padding-bottom: 10px;
      position: relative;
      padding-top: 5px;
      a {
        border: 1px solid $gray-100;
        min-width: 42px;
        height: 42px;
        padding: 5px;
        border-radius: 0;
        text-align: center;
        &.disabled {
          color: $gray-300;
          position: relative;
          pointer-events: auto;
          &:before {
            content: '';
            width: 100%;
            height: 1px;
            background: $gray-900;
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 0;
          }
        }
        &.active {
          border: 1px solid $gray-500;
          background-color: transparent;
          border-radius: 0;
          color: $black;
        }
        &.not-available {
          cursor: default;
          background: #E8E3E7;
          color: $gray-600;
          pointer-events: auto;
        }
        &:hover{
          span.color-name{
            display: inline-block !important;
            position: absolute;
            border: 1px solid $gray-300;
            background-color: $white;
            top: 5px;
            left: 30px;
            z-index: 10;
            font-size: 10px;
            padding: 3px;
            border-radius: 3px;
            line-height: 1;
            height: auto;
            color: $black !important;
          }
        }
        span {
          height: 30px;
          min-width: 30px;
          display: inline-block;
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 32px;
        }
        span.product-color {
          border: 1px solid $gray-300;
        }
      }
      i {
        position: absolute;
        top: -2px;
        left: 50%;
        font-size: 17px;
        background: #ffffff;
        width: 18px;
        height: 14px;
        line-height: 15px;
        margin-left: -9px;
      }
    } 
  }

  .color-popover-name {
    text-transform: uppercase;
    margin-top: .5rem;
  }

  .color-icon {
    @include product-color-icon()
  }

  .product-description, .product-card__mobile-tabs, .product-card__opinions, .product-card__recently-viewed, .set-container, .associated {
    h2 {
      font-size: 20px;
      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
  }

  .product-description {
    h2 {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .product-description-tabs {
    .plain-link {
      text-transform: uppercase;
      text-decoration: none !important;
      i {
        vertical-align: middle;
      }
    }
  }
  .product-description-item {
    .product-color-name{
      min-height: 24px;
    }
  }
  .product-quantity {
    border: 1px solid $gray-500;
    width: 115px;
    min-height: 40px;
    -webkit-touch-callout: none; /* iOS Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    input {
      width: 36px;
      height: 36px;
      line-height: 34px;
      border: none;
      text-align: center;
      outline: none !important;

    }
    a {
      height: 34px;
      width: 34px;
      line-height: 34px;
      border: 1px solid transparent;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      text-decoration: none !important;
      &:first-child{
        border-right: 1px solid $gray-300;
      }
      &:last-child{
        border-left: 1px solid $gray-300;
      }
    }
  }
  .cupboard-list-container{
    i{
      font-size: 34px;
    }
  }
  .btn-secondary{
    line-height: 15px !important;
    padding: 3px 40px;
  }
  .model-group-link{
    width: 40px;
    height: auto;
    display: inline-block;
    padding: 5px;
  }
  .model-group-img-mini{
    width: 30px;
    height: auto;
  }
  .model-group-img-container{
    height: 70px;
    width: 54px;
    display: inline-block;
  }
  .model-group-img-placeholder{
    position: relative;
    width: 40px;
    display: inline-block;
    padding-top: 60px;
    height: 0;
    background-position: center center;
    background-color:#f7f7f7;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='-4.313 0 28.313 24' enable-background='new -4.313 0 28.313 24' xml:space='preserve'%3E%3Cpath fill='%23D3D3D3' d='M12.15,19.05c-1.275,0-2.475-0.3-3.45-0.975c-0.975-0.675-1.875-1.5-2.475-2.55 C5.55,14.475,5.25,13.275,5.25,12c0-1.35,0.3-2.55,0.9-3.6c0.6-1.05,1.425-1.95,2.475-2.55c0.975-0.6,2.1-0.9,3.375-0.9 c1.275,0,2.4,0.3,3.45,0.975l2.175-3.6C15.825,1.275,13.95,0.75,12,0.75c-2.025,0-3.9,0.525-5.625,1.575 C4.65,3.3,3.3,4.725,2.25,6.45c-0.975,1.725-1.5,3.6-1.5,5.625c0,2.024,0.525,3.899,1.5,5.625c0.975,1.725,2.4,3.075,4.05,4.05 c1.725,0.975,3.6,1.5,5.625,1.5c2.025,0,3.9-0.525,5.625-1.5l-2.1-3.6C14.475,18.75,13.35,19.05,12.15,19.05z'/%3E%3C/svg%3E");
    background-size: 40%;
    vertical-align: middle;
  }
}
.pswp .pswp__bg{
  background: transparent;
  opacity: 1;
}

.opinions-container{
  .bg-gray {
    background-color: $gray-100;
    .vue-star-rating{ 
      &-star {
        display: inline-flex !important;
      }
    }
  }
  .opinion-item{
    border-bottom: 1px solid $gray-100;
    line-height: 16px;
  }
  .opinions-rate-avg{
    font-size: 70px;
    line-height: 1.25;
    font-weight: bold;
  }
  .stars-bars{
    .progress{
      border-bottom: 1px solid $gray-300;
      margin-top: 8px;
    }
    .progress-bar{
      height: 3px;
      background: theme-color('primary');
    }
  }
  .vue-star-rating{
    display: inline-block !important;
  }
  .opinion-list{

  }
}

.size-table-container{
  a{
    font-size: 0.7rem;
    text-transform: lowercase;
    text-decoration: underline !important;
  }
}
.set-container{
  h3:not(.light){
    font-size: 1.3rem;
  }
  .prices{
    font-size: 1rem !important;
    s{
      font-size: 0.8rem !important;
    }
  }
  .set-prod-desc{
    font-size: 0.7rem !important;
    line-height: 20px;
  }
}


#product-data {
  // @include media-breakpoint-down(md){
  //   overflow-x: hidden;
  // }
  // @include media-breakpoint-up(md){
  //   width: 340px;
  //   overflow-x: hidden;
  //   padding-right: 20px;
  //   opacity: 0;
  // }
  // @include media-breakpoint-up(lg){
  //   width: 300px;
  // }
  // @include media-breakpoint-up(xl){
  //   width: 360px
  // }
  // @media(min-width: 1600px){
  //   width: 527px;
  // }
  // @media all and (-ms-high-contrast:none){
  //   overflow-y: hidden;
  // }
  .product-data-content {
    &__stars {
      color: $black;
      display: flex;
      align-items: center;
      font-size: 10px;
      i {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .attribute-title {
    text-transform: uppercase;
    color: #DE5D71;
  }
}
.scrolled-down .fixed-product {
  @include media-breakpoint-up(md) {
    position: fixed;
    top: 88px;
    bottom: 30px;


  }
}
.scrolled-down #product-data.absolute-product{
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 30px;
    overflow-y: hidden;
  }
}
.simplebar-scrollbar:before{
  background: transparent !important;
}
div.popover-body .model-group-img{
  display: block !important;
}
.product-long-description{
  height: 70px;
  overflow: hidden;
  &.open{
    height: auto;
  }
}
.description-button-show, .description-button-hide{
  cursor: pointer;
}

.photos-carousel-wrapper {
  @include media-breakpoint-down(xs) {
    min-height: 510px;
  }
}

.product-carousel {
  .VueCarousel-wrapper {
    margin: 0 60px;
    padding: 15px 0;
    width: auto;
  }
  .VueCarousel-slide {
    // display: flex;
    // justify-content: center;
  }
}