.basket{
  &-product-title {
    word-break: break-word;
  }
  .btn-link{
    width: 40px !important;
    padding: 0 !important;
  }
  .list-unstyled{
    font-weight: 600;
    li{
      overflow: hidden;
      border-bottom: 1px solid $gray-300;
      margin-left: -8px;
      margin-right: -8px;
      &.shown {
        .mini-basket-item {
          @media(min-width:768px){
            transform: translateX(45px);
          }
        }  
      }
    }
a{
    border: none;
    line-height: 16px;
    &.img-basket{
        flex: 0 0 80px;
    }
}
    .basket-product-attr{
        font-weight: 300;
    }
    .image-wrapper {
        position: relative;
        flex: 0 0 80px;
    }
    .basket-remove{
      text-align: center;
      position: absolute;
      top: 50%;
      left: -45px;
      transform: translateY(-50%);
      i{
        font-size: 25px;
      }
    }
    .mini-basket-item{
      margin-left: 5px;
      display: flex;
      align-items: flex-start;
      margin-right: 50px;
      transition: transform .3s;
      transform: translateX(45px);
      @media(min-width: 768px){
        transform: translateX(0px);
      }
      .img-placeholder{
        position: relative;
        width: 100%;
        display: block;
        padding-top: 150%;
        height: 0;
        background-position: center center;
        background-color:#f7f7f7;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='-4.313 0 28.313 24' enable-background='new -4.313 0 28.313 24' xml:space='preserve'%3E%3Cpath fill='%23D3D3D3' d='M12.15,19.05c-1.275,0-2.475-0.3-3.45-0.975c-0.975-0.675-1.875-1.5-2.475-2.55 C5.55,14.475,5.25,13.275,5.25,12c0-1.35,0.3-2.55,0.9-3.6c0.6-1.05,1.425-1.95,2.475-2.55c0.975-0.6,2.1-0.9,3.375-0.9 c1.275,0,2.4,0.3,3.45,0.975l2.175-3.6C15.825,1.275,13.95,0.75,12,0.75c-2.025,0-3.9,0.525-5.625,1.575 C4.65,3.3,3.3,4.725,2.25,6.45c-0.975,1.725-1.5,3.6-1.5,5.625c0,2.024,0.525,3.899,1.5,5.625c0.975,1.725,2.4,3.075,4.05,4.05 c1.725,0.975,3.6,1.5,5.625,1.5c2.025,0,3.9-0.525,5.625-1.5l-2.1-3.6C14.475,18.75,13.35,19.05,12.15,19.05z'/%3E%3C/svg%3E");
        background-size: 40%;
      }
    }

  }
  .fixed{
    .mini-basket-buttons{
      margin-right: -100%;
    }
  }
  &.show{
    .fixed{
      .mini-basket-buttons{
        position: fixed;
        bottom: 0;
        padding-bottom: 10px;
        background: $white;
        border-top: 1px solid $gray-100;
        width: 25vw;
        margin-right: 0;
        transition: margin .7s;
        @media(max-width: 991px){
          width: 35vw;
        }
        @media(max-width: 767px){
          width: 50vw;
        }
        @media(max-width: 480px){
          width: 80vw;
        }
      }
      .list-unstyled{
        margin-bottom: 125px !important;
      }
    }
  }


  .btn{
    width: 80%;
    padding-left: 1rem;
    padding-right: 1rem;  
    @media screen and (max-width: 359px) {
      width: 85%;
    }
  }
}
