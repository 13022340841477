@use "sass:color";

//colors
$white: #ffffff;
$gray-100: #f2f2f2;
$gray-300: #dbdbdb;
$gray-500: #b2b2b2;
$gray-600: #a0a0a0;
$gray-900: #7a7a7a;
$black:  #2d3233;

$gold : #cebb8e;
$gold-light : #e2d7c3;

$primary: #de5d71;
$mastivo-primary: #de5d71;
$blue: #0089f9;
$green: #509232;
$yellow: #fcea00;
$warning-bg: #fdf3e3;
$red: #d40d1b;
$danger: #d40d1b;
$success: #509232;
$warning: #face00;
$danger-bg: #fdeded;
$success-bg: #f6fffa;
$red-light: #df4a54;

$headings-color: $black;
$theme-colors: (
        primary: $mastivo-primary,
        secondary: $black,
        success: $success,
        info: $blue,
        warning: $warning,
        danger: $danger,
        light: $gray-100,
        dark: $gray-900
);
$alert-colors: (
        danger: #d40d1b,
        success: #509232,
        warning: #fcae00,
        info: $blue,
        dark: $gray-900
); 
$alert-colors-bg: (
        danger: #fdeded,
        success: #f6fffa,
        warning: #fdf3e3,
        info: color.adjust($blue, $lightness: 50%),
        dark: $gray-100
);
$alert-border-radius: 0px !important;
$hr-border-color:$gray-500;

// layout settings

$grid-gutter-width: 20px;

$container-max-width:1640px;
$container-gutter : 10px;
$header-bg:transparent;

$body-bg:$white;

// Page header
$page-header-height: 167px;

//typo

$font-size-base: rem(12px);
$font-family-base: 'Montserrat', sans-serif;
$font-weight-base:500;
$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: 700;
$h1-font-size: 2.8rem;
$h2-font-size: 2rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1rem;
$h5-font-size: 1rem;
$h6-font-size: .875rem;


//buttons

$btn-border-width: 3px;
$btn-font-family: $headings-font-family;
$btn-font-weight: 400;
$btn-text-transform: uppercase;
$btn-font-size:rem(12px);
$btn-border-radius :0px;
$btn-padding-x: 40px;
$btn-padding-y: 8px;
$input-btn-padding-y: 0.8rem;
$input-btn-padding-x: rem(73px);
$modal-md: 600px;

$input-height : 36px;


//modal

$modal-content-border-radius:0;
$modal-header-border-width:0;
$modal-footer-border-width:0;
$modal-content-border-width:0;
$modal-backdrop-opacity:0.4;
$modal-backdrop-bg:$gray-500;

$modal-inner-padding: 2rem;
$modal-header-padding: 2rem;

// old






// popover
$zindex-popover: 990;

//forms
$input-padding-x: 0.8rem;


//borders
$input-border-radius :0 ;
//$btn-border-radius :2px;

//alerts
@each $color, $value in $alert-colors {
  .alert-#{$color} {
        color: $value !important;
  }
}
@each $color, $value in $alert-colors-bg {
        .alert-#{$color} {
          background-color: $value !important;
        }
      }
$card-border-width : 0;

$box-shadow-base : 0 5px 9px 0 rgba(152, 182, 191, 0.18), 0 2px 10px 0 rgba(0, 0, 0, 0.05);

$product-grid: (
        xs: 2,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3
);
$product-grid-wide: (
        xs: 2,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4
);
$product-grid-list: (
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1
);

.fixed-top {
        z-index: 2000 !important;
}