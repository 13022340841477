#size-table{
  display: none;
  &.collapsing, &.collapse{
    display: block;
  }
  table{
    border: 1px solid $gray-300;
    th{
      padding: 0.75rem 0;
    }
  }
}
.size-table-modal {
  .modal-body{
    padding-bottom: 15px !important;
  }
  footer {
    display: none;
  }
  .table{
    color: inherit;
    thead{
      tr{
        border-bottom: 0 none;
        th{
          font-weight: bold;
          text-align: left;
          padding: 5px;
          font-size: 12px;
          color: #fff;
          background-color: theme-color('primary');
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px;
          white-space: nowrap;
        }
      }
    }
    &.table-responsive-lg {
      max-width: fit-content;
      max-width: -moz-fit-content;
      margin: 0 auto;
    }
  }
}

