.bar-loader-placeholder {
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.bar-loader {
    background-color: $white;
}
.bar-loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: $blue;
    animation: barLoading 2s linear infinite;
}

@keyframes barLoading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}