
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.btn-icon{
    background: transparent;
    border:0 transparent;
    font-weight:100;
    outline: none !important;
    i{
        margin-right: 10px;
        bottom: -3px;
        position: relative;
        font-size: 18px !important;
        color: #000000 !important;
    }
}
