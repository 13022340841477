.blog-item {

  display: flex;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: $hr-border-width solid $hr-border-color;

  @include media-breakpoint-down(sm){
    flex-direction: column !important;
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;

    &__photo {
      width: 100% !important;
      img {
        margin-right: 0!important;
        margin-left: 0!important;
        max-width: 100%!important;
      }
    }
    &__content {
      width: 100% !important;
    }
  }

  &__photo {
    width: 50%;
    margin-bottom: $grid-gutter-width * 0.5;

    img {
      margin-right: -15%;
      max-width: 115%;
    }

  }

  &__content {
    width: 50%;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align:center;

  }

  &--reverse {
    flex-direction: row-reverse;

    .blog-item {

      &__photo {

        img {
          margin-right: 0%;
          margin-left: -15%;
        }

      }


    }
  }
}
.homepage-section-two-banner-half{
  .blog-item__content{
    flex: 0 0 50%;
    .btn{
      max-width: 100%;
    }
  }
  .blog-item__photo {
    flex: 0 0 50%;
    img {
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;
    }
  }
}