<template>
    <div>
        <div class="d-flex align-items-center justify-content-center">
            <div class="icon icon--order-success svg">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72px"
                    height="72px"
                    >
                    <g
                        fill="none"
                        stroke="#8EC343"
                        stroke-width="2"
                        >
                        <circle
                            cx="36"
                            cy="36"
                            r="35"
                            style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px;"
                            />
                        <path
                            d="M17.417,37.778l9.93,9.909l25.444-25.393"
                            style="stroke-dasharray:50px, 50px; stroke-dashoffset: 0px;"
                            />
                    </g>
                </svg>
            </div>
            <div class="ml-3">
                {{ data.message || 'Message Sent' }}
            </div>
        </div>
        <div
            v-if="data.sendAgain"
            class="text-center mt-5"
            >
            <button
                class="btn btn-primary"
                @click.prevent="unhideForm(target)"
                >
                {{ $ll('formSendAgain') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    props: {
        target: { type: String, required: true }
    },
    computed: {
        fields() {
            return this.$store.getters['CustomForm/fields'](this.target)
        },
        data() {
            return this.$store.getters['CustomForm/data'](this.target)
        }
    },
    methods: {
        ...mapMutations({
            unhideForm: 'CustomForm/unhideForm'
        })
    }
}
</script>

<style>
@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
    display: inline
}

/* .svg img {
    display: none
} */

.icon--order-success svg path {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards
}
</style>
