.alert-danger {
  color: #d40d1b !important;
}

.alert-success {
  color: #509232 !important;
}

.alert-warning {
  color: #fcae00 !important;
}

.alert-info {
  color: #0089f9 !important;
}

.alert-dark {
  color: #7a7a7a !important;
}

.alert-danger {
  background-color: #fdeded !important;
}

.alert-success {
  background-color: #f6fffa !important;
}

.alert-warning {
  background-color: #fdf3e3 !important;
}

.alert-info {
  background-color: rgb(249, 252.3012048193, 255) !important;
}

.alert-dark {
  background-color: #f2f2f2 !important;
}

.fixed-top {
  z-index: 2000 !important;
}

.thumb-image {
  max-height: 9.5vh;
  opacity: 0.8;
  cursor: pointer;
}
.thumb-image:hover {
  opacity: 1;
}

.thumb-active {
  opacity: 1;
  max-height: 10vh;
}

.pswp__caption__center {
  max-width: none !important;
}

.pswp__ui--hidden .pswp__top-bar,
.pswp__ui--hidden .pswp__caption,
.pswp__ui--hidden .pswp__button--arrow--left,
.pswp__ui--hidden .pswp__button--arrow--right {
  opacity: 1 !important;
}