.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0px solid $white;
  margin: 20px 0;
  list-style: none;
  padding: 0px;

  li {
    &:first-child {
      margin-right: .75rem;
      .page-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: .5rem .5rem;
        border: 1px solid $gray-300;
        border-radius: 0px;
      }
    }
    &:last-child {
      margin-left: .75rem;
      .page-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: .5rem .5rem;
        border: 1px solid $gray-300;
        border-radius: 0px;
      }
    }

    .page-link {
      border: 0 none;
      font-size: 18px;
      color: $black;
      &:active,
      &:focus {
        color: $black !important;
        box-shadow: none;
      }
      &:hover {
        text-decoration: none !important;
        background-color: transparent !important;
      }
    }
  }

  &-separator {
    margin: 0 .75rem;
    font-size: 18px;
    color: $black;
  }
}
