.banderolle {
  &__outer {
    width: 100%;
  }

  font-size: rem(14px);
  background-color: theme-color('primary');
  color: white;
  min-height: 38px;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 4px 10px;
  &__content {
    > * {
      margin-bottom: 0;
    }
  }
}
