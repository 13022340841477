.search-container, .search-overlay{
  input, .form-field__placeholder{
    width: 100%;
    padding-right: 30%;
    @include media-breakpoint-down(sm) {
      padding-right: 50%;
    }
    @include media-breakpoint-down(xs) {
      padding-right: 100px;
    }
  }
  .form-field__append button{
    @include media-breakpoint-down(xs) {
      width: 45px;
    }
  }

}
 .search {
   &-hints {
     &__list, 
     &__no-results
         {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: auto;
       background-color: $white;
       z-index: 1000;
     }
   }
 }