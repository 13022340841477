
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.products-per-page {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    flex-wrap: nowrap;
    .item {
        cursor: pointer;
        width: 40px;
        padding: 5px 0;
        &.active {
            color: #de5d71;
            font-weight: bold;
            text-decoration: none;
            cursor: default;
            pointer-events: none;
        }
        &:hover {
            color: #de5d71;
            text-decoration: underline;
        }
    }
    span {
        display: flex;
        align-items:center;
        justify-content: flex-end;
    }
}
