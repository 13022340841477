html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;

  @include media-breakpoint-down(sm){
    overflow-x: hidden;
  }
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: 400 !important;
  }
  b,strong{
    font-weight: 700 !important;
  }
}


body {
  line-height: 1.5rem;
  // letter-spacing: 0.75px;
  padding-top: $page-header-height;
  @include media-breakpoint-down(md){
    padding-top: 47px;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #eee transparent;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $gray-100;
      border-radius: 6px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-100;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-500;
      border: 1px solid $gray-100;
      border-radius: 6px;
    }
  }
}
.fixed-top{
  @include media-breakpoint-up(lg) {
    top: 110px;
  }
  @include media-breakpoint-down(md){
    top: 45px;
  }
  @include media-breakpoint-down(xs){
    top: 40px;
  }
}
.scrolled-down{
  .fixed-top{
    top: 45px;
    @include media-breakpoint-down(xs){
      top: 40px;
    }
  }
}

.modal-backdrop {
  opacity: 1;      
}

.container{
  @media(min-width: 1660px) {
    max-width: 1640px !important;
  }
}

.container, .container-fluid {
  padding-right: $container-gutter;
  padding-left: $container-gutter;

  &--paddingless-lg-d {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &--paddingless-md-d {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.list-unstyled {
  margin-bottom: 0;
}

// text utils
.title-1 {
  font-size: 20px;
  font-family: $headings-font-family;
  color: $black;
  font-weight: 500;
}


@media(min-width: 1660px) {
  .col-xxl-1{
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2{
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3{
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5{
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7{
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8{
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9{
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10{
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11{
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12{
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.w-10 {
  width: 10% !important;
}
.w-20{
  width: 20% !important;
}
.w-30{
  width: 30% !important;
}
.w-40{
  width: 40% !important;
}
.w-60{
  width: 60% !important;
}
.w-70{
  width: 70% !important;
}
.w-80{
  width: 80% !important;
}
.w-90{
  width: 90% !important;
}
@include media-breakpoint-down(lg){
  .w-lg-100{
    width: 100% !important;
  }
}
@include media-breakpoint-down(md){
  .w-md-100{
    width: 100% !important;
  }
}
@include media-breakpoint-down(sm){
  .w-sm-100{
    width: 100% !important;
  }
}
@include media-breakpoint-down(xs){
  .w-xs-100{
    width: 100% !important;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
.light {
  font-weight:400;
}

@include media-breakpoint-down(lg){
  h1,.h1 {
    font-size: $h1-font-size * 0.8;
  }
  h2,.h2{
    font-size: $h2-font-size * 0.8;
  }
  h3,.h3{
    font-size: $h3-font-size * 0.8;
  }
  h4,.h4{
    font-size: $h4-font-size * 0.8;
  }

}
@include media-breakpoint-down(sm){
  h1,.h1{
    font-size: $h1-font-size * 0.7;
  }
  h2,.h2{
    font-size: $h2-font-size * 0.7;
  }
  h3,.h3{
    font-size: $h3-font-size * 0.7;
  }
  h4,.h4{
    font-size: $h4-font-size * 0.7;
  }
  h5,.h5{
    font-size: $h5-font-size * 0.7;
  }
  h6,.h6{
    font-size: $h6-font-size * 0.7;
  }
}
a[href^="mailto:"] {
  text-decoration: underline;
}
.clearer{
  clear: both;
}

.affix{
  @include media-breakpoint-up(md) {
    position: sticky !important;
  }
  @include media-breakpoint-down(md) {
    position: relative !important;
    top: 0 !important;
  }
}
.vue-affix, .affix-bottom {
  @include media-breakpoint-down(md) {
    top: 0 !important;
  }
}
.overlay-color{
  background: rgba(0, 0, 0, 0.3) !important;
  opacity: 1 !important;
}
.bg-gray{
  background-color: $gray-100;
}
.light-gray{
  color: $gray-500;
}
.text-black{
  color: $black;
}
.bottom-line{
  border-bottom: 1px solid $gray-300 !important;
}
.btn{
  text-transform: uppercase;
  // letter-spacing: 1px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:active, &:focus,
  &:active:focus {
    outline: none;
    box-shadow: none;    
  }
}

.btn-primary:not(.btn-secondary) {
  font-family: "Montserrat", sans-serif;
  background-color: theme-color('primary');
  border: none !important;
  height: 40px;
  color: $white;
  padding: 4px 40px;
  transition: background-color 0.5s;
  position: relative;
  overflow: hidden;
  &:hover{
    background-color: theme-color('primary');
    &:before{
      opacity: 1;
      width: 110%;
    }
  }
  &:before{
    content: '';
    position: absolute;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: rgba(255,255,255,0.3);
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    -o-transition: width 0.4s;
    transition: width 0.4s;
  }
  &:not(:disabled):not(.disabled):active:focus {
    outline: none;
    box-shadow: none !important;    
  }

}
.btn-black {
  @extend .btn-primary, :not(.btn-secondary);
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background-color: $black;
    color: $white;
  }
}

a.btn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.btn-long {
  width: 100%;
}

.btn-secondary{
  background-color: $white;
  border: 2px solid $black;
  color: $black;
  padding: 4px 40px;
  height: 40px;
  font-weight: 600;
  line-height: 28px !important;
  &:active, &:hover {
    background-color: $white;
    border-color: $gray-900;
    color: $gray-900;
  }
}
.btn-disabled,
.btn:disabled {
  background-color: $gray-300;
  border: 3px solid $gray-300;
  color: $white;
  cursor: default !important;
  &:active, &:hover {
    background-color: $gray-300;
    border: 3px solid $gray-300;
    color: $white;
  }
}
a:not(.btn){
  color: $black;
  &:active, &:hover, &:focus {
    text-decoration: none;
    color: theme-color('primary');
  }
}
.active a:not(.btn) {
  text-decoration: none;
  color: theme-color('primary');
}
a.no-hover{
  text-decoration: none !important;
  outline: none !important;
  color: initial !important;
}

.btn-text {
  background: transparent;
  display: inline;
  border: 0 none;
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: 1em;
  text-transform: none;
}

.btn-icon {
  &:disabled {
    color: $gray-500;
  }
  i[class^="i-"] {
    color: inherit !important;
  }
}

.i-close {
  cursor: pointer;
}
.box-shadow{
  box-shadow: $box-shadow-base;
}
.scroll-box{
  overflow-y: auto;
}
.border-none{
  border: none !important;
}
section.first-section{
  margin-top: -75px;
}

.container {
  padding-right: 0;
  padding-left: 0;
  @media(min-width: 1660px){
    max-width: 1570px;
    &.container-small{
      max-width: 1140px !important;
    }
  }
}
span.custom-arrow, span.custom-arrow-right{
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 3px;
  right: -3px;
  padding: 6px 8px;
  height: 15px;
  cursor: pointer;
  i {
    transition: .5s all;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid $black;
    display: block;
    margin-top: -2px;
  }
}
span.custom-arrow-right{
  left: auto;
  right: -3px;
}
.show{
  & > span.custom-arrow, & > span.custom-arrow-right{
    i{
      transform: rotate(180deg);
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.plain-link{
  font-weight: 300;
  border-bottom: none !important;
  text-decoration: underline;
  line-height: 1rem;
  &:hover, &:focus{
    text-decoration: underline !important;
  }
}
.text-underline{
  text-decoration: underline !important;
}

.btn-link-inline {
  &:not(.disabled) {
    cursor: pointer;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: transparent;
    &:hover {
      border-color: $black;
    }
    &:active {
      border-width: 1px 0 0 1px;;
    }
  }
}
.loader-overflow{
  background: transparent;
  transition: background .15s ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}
.loader-overflow.active {
  top: 0;
  background: rgba($white,0.35);
  z-index: 1000;
  pointer-events: all;
}
.success-icon:before {
  content: '';
  display: block;
  width: 8px;
  height: 14px;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
  transition: all 250ms ease;
  position: absolute;
  top: 13px;
  right: 30px;
}
.alert-dismissible:before, .alert-dark:before, .danger-icon:before {
  font-family: 'icons' !important;
  content: "\E905";
  font-style: normal;
  display: block;
  width: 10px;
  height: 16px;
  position: absolute;
  top: 10px;
  font-size: 21px;
  right: 25px;
}
.alert-dismissible button{
  color:transparent !important;
}
.warning-icon:before {
  content: '!';
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  display: block;
  width: 10px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 28px;
}
.alert{
  // border: 1px solid $gray-100 !important;
  // border-radius: $alert-border-radius;
  font-weight:600;
  text-transform: uppercase;
  // background-color: $white !important;
  padding: 0.6rem 2.5rem 0.6rem 1.25rem;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.28);
  z-index: 1;
  .success-icon:before {
    color: $success;
    border: solid #6eb100;
  }
  .danger-icon:before {
    color: $danger;
  }
  .warning-icon:before {
    color: $warning;
  }
  .alert-dark:before{
    color: $dark;
  }
}


.form-group {
  align-items: center;
}
.flex-xs-column-reverse{
  @include media-breakpoint-down(xs) {
    flex-direction: column-reverse !important;
  }
}
small i {
  font-size: 14px !important;
  vertical-align: text-bottom;
}
.popover{
  .i-close{
    font-size: 16px;
    float: right;
    z-index: 1;
    position: relative;
  }
}
.mb-gutter {
  margin-bottom: 30px;
}
.mt-gutter {
  margin-top:30px;
}
.club-info-banner{
  border: 10px solid $gray-100;
}
.or-section{
  position: relative;
  margin: 2rem 0;
  span{
    background-color: #ffffff;
    padding: 0 15px;
    position: absolute;
    top: -13px;
    left: 50%;
    width: 60px;
    margin-left: -30px;
  }
  &:before{
    border-top: 1px solid #dbdbdb;
    display: block;
    content: '';
    width: 50%;
    margin: 0 auto;
  }
}
.toggle-view {
  display: flex;
  @include media-breakpoint-down(sm){
    position: absolute;
    top: -67px;
    right: 12px;
  }
}
.popover{
  z-index: 1100;
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.pos-reset {
  top: 0;
  left: 0;
}

.black-text {
  color: $black;
}

.negative-indent {
  text-indent: -2px;
  &--big {
    text-indent: -10px;
  }
}
#myCustomTrustbadge {
  > div {
    > div {
      > div {
        z-index: 1000 !important;
      }
    }
  }
}