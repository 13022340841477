.homepage-icons-bar {
  display: flex;
  flex-wrap: wrap;
  &__item {
    text-align: center;
    width: 25%;
    color: $black;
    padding: 0 0.5rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
      width: 50%;
    }

    img {
      max-width: 100%;
      width: 70px;
      display: block;
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    p {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
    }
  }
}
