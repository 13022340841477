
.form {

  &-field {
    display: block;
    margin-bottom: 1.5rem;
    position: relative;
    padding-top: 0.5rem;

    &__input,
    &__select,
    &__textarea {
      border-color: $gray-100;
      background-color: $gray-100;
      padding: 0.4rem 0;
      height: 45px;
      margin-top: 10px;
      padding-left: 15px;
      font-size: 11px;
      &::placeholder {
        color: transparent;
      }

      &:focus {
        box-shadow: 0 0 0;
        background-color:$gray-100;
        border-color:$gray-100;
      }

      &--invalid {
        border-color: theme-color("danger");
        border-width: 0 0 1px 0;
      }
      &--success {
        border-color: theme-color("success");
        border-width: 0 0 1px 0;
      }
      &--warning {
        border-color: theme-color("warning");
        border-width: 0 0 1px 0;
      }
      option{
        font-weight: 300;
      }
      &[type="file"]{
        background-color: transparent;
        border: none;
        padding-left: 0;
        height: auto;
      }
    }
    &__checkbox {
      position: relative;
      margin-top: -0.5rem;

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        + .form__checkbox-label {
          cursor: pointer;
          position: relative;
          word-break: break-word;

          &:before {
            content: '';
            background: $gray-100;
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            top: 0;
            margin-right: 1em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
          &:after {
            content: '';
            display: block;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 1px 1px 0 !important;
            transform: rotate(45deg);
            transition: all 250ms ease;
            position: absolute;
            top: 2px;
            left: 6px;
            opacity: 0;
          }
        }
        &:checked {
          + .form__checkbox-label {
            &:after {
              border: 2px solid $gray-900;
              opacity: 1;
            }
          }
        }
        &:focus {
          + .form__checkbox-label {
            &:before {
              outline: none;
              border-color: theme-color("primary");
            }
          }
        }
        &:disabled {
          + .form__checkbox-label {
            &:before {
              background: $white;
              border: 1px solid $gray-100;
            }
            &:after {
              border-color: $gray-500;
            }
          }
        }
        + .form__checkbox-label {
          margin: 0;
          line-height: 20px;
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
          &.disclaimer:before, &.disclaimer:after{
            display: none;
          }
        }
        + .form__checkbox-label-cupboard {
          line-height: 16px !important;
          border: 1px solid rgb(45,50,50);
        }
      }

      &--alternate {
        input[type="checkbox"] {

          + .form__checkbox-label {

            &:before {
              content: '';
              background: $white;
              border: 1px solid $gray-600;
              top: 6px;
            }
            // &:after {
            //   content: '';
            //   display: block;
            //   width: 5px;
            //   height: 10px;
            //   border: solid $white;
            //   border-width: 0 1px 1px 0 !important;
            //   transform: rotate(45deg);
            //   transition: all 250ms ease;
            //   position: absolute;
            //   top: 2px;
            //   left: 6px;
            //   opacity: 0;
            // }
          }

          &:focus {
            + .form__checkbox-label {
              &:before {
                outline: none;
                border-color: $black;
              }
            }
          }
          &:disabled {
            + .form__checkbox-label {
              &:before {
                background: $white;
                border: 1px solid $gray-200;
              }
              &:after {
                border-color: $gray-500;
              }
            }
          }
          
        }
      }

      &--color {
        position: relative;
        .form_checkbox-color {
          display: block;
          position: absolute;
          left: 8px;
          top: 2px;
          width: 20px;
          height: 20px;
          z-index: 1;
          pointer-events: none;
        }
        label:before{
          border-color: $white !important;
        }
        &.color-white{
          .form_checkbox-color {
            left: 9px;
            top: 3px;
            width: 18px;
            height: 18px;
          }
          label:before{
            border: 1px solid $black !important;
            width: 20px!important;
            height: 20px!important;
          }
        }
      }

      &--color,
      &--bordered {
        input[type="checkbox"] + label.form__checkbox-label:after {
          border-width: 2px !important;
          transform: rotate(0) !important;
          width: 28px !important;
          height: 28px !important;
          top: -4px !important;
          left: -4px !important;
        }
      }

      &--image {
        
        input[type="checkbox"] + label.form__checkbox-label {
          &::after {
            top: 50%;
            left: 4px;
            transform: rotate(45deg) translateY(-60%) translateX(-50%);
          }
        }

        .form__checkbox-label {
          display: inline-flex;
          align-items: center;

          
          i {
            font-size: 28px;
            display: inline-block;
            margin-left: 16px;
          }
        }
      }
    }

    &__file{
      border:0 none;
      height: auto;
      position: relative;
      padding: 0;
      display: flex;

      &-name{
        border-color: transparent;
        padding-left: 0.5rem;
        padding-right: 0;
        flex-grow: 1!important;
      }

      .btn{
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
        margin: 0 !important;

        i:before {
          font-size:24px;
          margin-right: 5px;
          line-height: 5px;
          position: relative;
          bottom: -3px;
        }

        > input[type="file"]{
          position: absolute;
          width: 0;
          height: 0;
          z-index: -1;
        }
      }


     }


    &__placeholder {
      text-transform: uppercase;
      position: absolute;
      pointer-events: none;
      transition: all .25s;
      top: -10px;
      left: 0;
      color: $gray-500;
      font-family: "Montserrat", sans-serif;
      white-space: nowrap;
      width: 80%;
      overflow: hidden;
      font-size: 9px;
      text-overflow: ellipsis;
      &.required-label:before{
        content: '* ';
        font-weight: bolder;
        font-size: 12px;
        color: $gray-900;
        vertical-align: middle;
      }
    }

    &__hint {

      &--invalid {
        color: theme-color("danger");
      }
    }

    &__append {
      position: absolute;
      right: 0;
      top: 28px;
    }

    &__input:placeholder-shown:not(:-webkit-autofill):not(:focus) + &__placeholder,
    &__textarea:placeholder-shown:not(:-webkit-autofill):not(:focus) + &__placeholder {
      top: 1.7rem;
      left: 15px;
      font-size: 11px;
      color: $gray-900;
    }

    &--center {
      .form-field {
        &__input,
        &__select,
        &__textarea {
          text-align: center;
        }
        &__placeholder {
          text-align: center;
          width: 100%;
          left: 0 !important;
        }
        &__hint {
          text-align: center;
          width: 100%;
        }
      }
    }
    
    &--alternate {
      .form-field {
        &__input,
        &__select,
        &__textarea {
          background-color: $white;
          border: 1px solid $gray-600;
        }
        &__placeholder {
          text-transform: none;
        }
      }
    }

    &--large {
      .form-field {
        &__input,
        &__select,
        &__textarea {
          font-size: 200% !important;
          height: calc(2 * 36px);
        }

        &__placeholder {
          font-size: 140% !important;
        }

        &__append{
          top: 30px;
        }
      }
      .form-field__input:placeholder-shown:not(:-webkit-autofill):not(:focus) + .form-field__placeholder,
      .form-field__textarea:placeholder-shown:not(:-webkit-autofill):not(:focus) + .form-field__placeholder {
        font-size: 200% !important;
        top: 2.2rem;
      }
    }
    .v-select{
      .vs__dropdown-toggle{
        border-width: 0 0 1px 0;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
        &:after{
          display: none;
        }
        .vs__selected-options{
          cursor: pointer;
        }
        .vs__selected{
          padding: 0;
          margin: 0;
          top: 11px;
          left: 8px;
          transition: all .25s;
          position: absolute;
          text-transform: uppercase;
        }
        .vs__search{
          height: 30px;
          cursor: pointer;
        }
      }
      .vs__dropdown-menu{
        border: 1px solid $gray-300;
        border-radius: 0;
        overflow: auto;
        border-top: none;
        box-shadow: none;
        padding-top: 0;
        top: calc(100%);
        li a{
          border: none;
        }
        .active > a{
          background-color: $red !important;
          color: $white !important;
        }
        .vs__dropdown-option--highlight, a:hover{
          background-color: #F6CFCF !important;
          color: $white !important;
        }
      }
      .vs__actions{
        padding: 10px 6px 0 3px;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 14px;
        .vs__clear, .vs__open-indicator{
          display:none;
        }
        &:before{
          content: '';
          width: 0;
          height: 0;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 6px solid $black;
          display: block;
          transform: rotate(0deg);
          margin-top: -10px;
          transition: transform .25s;
        }

      }
      &.vs--open{
        .vs__actions:before{
          transform: rotate(180deg) !important;
        }
        .vs__dropdown-toggle{
          border-bottom: 1px solid rgba(60,60,60,.26)
        }
        .vs__selected{
          text-transform: uppercase;
          top: -10px;
          font-size: 90%;
        }
      }
    }
    .required-label{
      .v-select .vs__selected:before{
        content: '* ';
        font-weight: bolder;
        font-size: 12px;
        color: $gray-900;
        vertical-align: middle;
        width: 10px;
      }
    }
    &__radio {
      margin: 0.5rem;
      position: relative;

      input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .form__radio-label {
          cursor: pointer;
          &:before {
            content: '';
            background: $white;
            border-radius: 100%;
            border: 1px solid $gray-200;
            display: inline-block;
            width: 15px;
            height: 15px;
            position: relative;
            top: 0;
            margin-right: 1em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
          }
        }
        &:checked {
          + .form__radio-label {
            &:before {
              background-color: theme-color("primary");
              box-shadow: inset 0 0 0 3px $gray-200;
              outline: none;
              border-color: theme-color("primary");
            }
          }
        }
        &:focus {
          + .form__radio-label {
            &:before {
              outline: none;
              border-color: theme-color("primary");
            }
          }
        }
        &:disabled {
          + .form__radio-label {
            &:before {
              box-shadow: inset 0 0 0 3px $gray-200;
              border-color: darken($gray-200, 25%);
              background: darken($gray-200, 25%);
            }
          }
        }
        + .form__radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }

    &:not(.category-select){
      .v-select{
        .vs__dropdown-toggle{
          background: $gray-100;
          height: 45px;
          border-color:$gray-100;
          .vs__selected{
            top: 14px;
          }
          .vs__search{
            height: 40px;
          }
          .vs__actions{
            top: 18px;
          }
        }
        &.vs--open{
          .vs__selected{
            top: -20px;
            font-size: 11px;
          }
        }
      }
    }
    &__select--invalid{
      .v-select{
        .vs__dropdown-toggle{
          border-bottom-color: $red
        }
      }
    }
  }



  &__switch {
    margin: 0.5rem;
    position: relative;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      cursor: pointer;

      + .form__switch-label {
        cursor: pointer;
        position: relative;
        margin: 0;

        span:after {
          content: attr(data-unchecked-title);
          display: inline-block;
        }

        &:before {
          display: inline-block;
          content: '';
          width: 30px;
          height: 10px;
          background: $gray-400;
          border-radius: 5px;
          margin-right: 5px;
        }
        &:after {
          content: '';
          background: $gray-200;
          width: 18px;
          height: 18px;
          display: inline-block;
          position: absolute;
          border-radius: 8px;
          left: 0;
          margin-top: 1px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 16px rgba(0, 108, 181, 0);
          transition: all 250ms ease;
        }
        + input[type="radio"] {
          position: absolute;
          left: 0px;
          top: 1px;
          bottom: 1px;
          display: block;
          width: 100%;
          height: 20px;
          z-index: -1;
          cursor: pointer;
        }
      }
      &:checked {
        + .form__switch-label {

          + input[type="radio"] {
            z-index: 100;
          }
          span:after {
            content: attr(data-checked-title);
            display: inline-block;
          }
          &:after {
            background-color: theme-color("primary") !important;
            margin-left: 12px;
          }
        }
      }
      &:focus {
        //@TODO
      }
      &:disabled {
        //@TODO
      }
    }

    &--disabled {
      pointer-events: none;
      input[type="radio"]:valid {
        position: absolute;
        opacity: 0;
        + label.form__switch-label:after {
          background: $gray-500 !important;
        }
      }
    }

    &__right {
      text-align: right;
      input[type="checkbox"] {
        + .form__switch-label {
          padding-right: 42px;

          &:before {
            display: inline-block;
            content: '';
            width: 30px;
            height: 10px;
            background: $gray-400;
            border-radius: 5px;
            margin-right: 5px;
            position: absolute;
            right: 0;
            top: 6px;
          }
          &:after {
            content: '';
            background: $gray-200;
            width: 18px;
            height: 18px;
            display: inline-block;
            border-radius: 8px;
            margin-top: 1px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 16px rgba(0, 108, 181, 0);
            transition: all 250ms ease;
            position: absolute;
            right: 18px;
            left: auto;
          }
        }

        &:checked {
          + .form__switch-label {

            &:after {
              right: 4px;
            }
          }
        }
      }
    }

  }

  &__select {

    &.dropup,
    &.dropdown {
      position: static !important;
    }

    .dropdown-menu {

      .selected {
        color: $dropdown-link-hover-color;
        text-decoration: none;
        background-color: $dropdown-link-hover-bg;
      }

      .bs-searchbox {
        padding: 0;
        margin: 0 0 5px;

        .form-control {
          font-size: $font-size-sm;
        }
        &.form__select__multiple {
          margin-right: 45px;
        }
      }
      .bs-actionsbox.dropdown {
        padding: 0;

        .btn-icon {
          position: absolute;
          top: -38px;
          right: 0px;
        }
        .dropdown-menu {
          min-width: initial;
          bottom: auto;
          margin-bottom: auto;
        }
      }
      .hidden {
        display: none;
      }

      .dropdown-item {
        padding: 5px 0.75rem;
      }
    }
    .dropdown-toggle span {
      margin-top: 2px;
      margin-bottom: -2px;
    }
    &__list {

      &-big {
        > .dropdown-menu {
          > .dropdown-menu.inner {
            height: 320px !important;
          }
        }
      }

      > .dropdown-toggle {
        display: none;
      }
      > .dropdown-menu {
        display: block !important;
        position: static;
        box-shadow: 0 0 0;
        margin: 0;
        border-width: 0;
        overflow: visible !important;

        > .dropdown-menu.inner {
          display: block !important;
          overflow-y: hidden;
          height: 120px;
          border: $dropdown-border-width solid $dropdown-border-color;
          border-radius: $border-radius;
        }
      }
      a {
        outline: 0 !important;
      }
    }
  }
}

.basket__view {
  .form-field__checkbox {
    display: flex;
    label {
      padding-left: 24px !important;
      &::before {
        left: 0px !important;
        margin-right: 0 !important;
        position: absolute !important;
      }
    }
  }
}

#consent-more, #consent-collapse {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: -20px;
}


