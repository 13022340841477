.left-menu{
  padding-left: 0;
  list-style: none;
  text-transform: uppercase;
  overflow: hidden;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  .nav-link{
    display: inline-block;
    margin: 0 0 0 35px;
    padding: 0;
    line-height: 18px;
    height: 20px;
    vertical-align: middle;

  }
  .active{
    border-bottom: 3px solid $black;
  }
  .nav-item{
    position: relative;
    line-height: 30px;
    &.show{
      .sub-menu{
        max-height: 500px;
        transition-duration: 1s;
      }
    }
  }
  .sub-menu{
    max-height: 0;
    list-style: none;
    transition: max-height 0.8s ease-out;
    overflow: hidden;
    padding-left: 30px;
    .sub-nav-item{
      position: relative;
      padding-left: 0px;
      line-height: 30px;
      a{
        margin-left:35px;
        display: inline-block;
        height: 23px;
        line-height: 20px;
        vertical-align: middle;
        span{
          display: inline-block;
        }

      }
      &.show{
        .sub-sub-menu {
          max-height: 500px;
          transition-duration: 2s;
        }
      }
    }
    > li:last-child{
      margin-bottom: 30px !important;
    }
  }
  .sub-sub-menu{
    max-height: 0;
    list-style: none;
    overflow: hidden;
    transition: max-height 0.8s ease-out;
    padding-left: 30px;
  }
}
