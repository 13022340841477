@font-face {
  font-family: 'icons';
  src:  url('fonts/icons.eot?zruqe');
  src:  url('fonts/icons.eot?zruqe#iefix') format('embedded-opentype'),
    url('fonts/icons.ttf?zruqe') format('truetype'),
    url('fonts/icons.woff?zruqe') format('woff'),
    url('fonts/icons.svg?zruqe#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-facebook:before {
  content: "\e926";
}
.i-instagram:before {
  content: "\e927";
}
.i-twitter:before {
  content: "\e928";
}
.i-user:before {
  content: "\e929";
}
.i-youtube:before {
  content: "\e92a";
}
.i-conversation:before {
  content: "\e920";
}
.i-gift:before {
  content: "\e922";
}
.i-package:before {
  content: "\e923";
}
.i-shopping-bag:before {
  content: "\e924";
}
.i-truck:before {
  content: "\e925";
}
.i-tiktok:before {
  content: "\e921";
}
.i-trolley:before {
  content: "\e91f";
}
.i-arrow_bread_left:before {
  content: "\e91e";
}
.i-menu:before {
  content: "\e91c";
}
.i-phone:before {
  content: "\e91d";
}
.i-arrow-down-small:before {
  content: "\e904";
}
.i-arrow-left-small:before {
  content: "\e908";
}
.i-arrow-right-small:before {
  content: "\e90c";
}
.i-arrow-up-small:before {
  content: "\e90e";
}
.i-calendar:before {
  content: "\e90f";
}

.i-mail:before {
  content: "\e912";
}
.i-loupe:before {
  content: "\e913";
}
.i-person:before {
  content: "\e914";
}
.i-cupboard:before {
  content: "\e915";
}
.i-shield:before {
  content: "\e916";
}
.i-star:before {
  content: "\e917";
}
.i-timer:before {
  content: "\e918";
}
.i-cart:before {
  content: "\e919";
}
.i-download:before {
  content: "\e90d";
}
.i-add:before {
  content: "\e90a";
}
.i-cupboard_add:before {
  content: "\e90b";
}
.i-arrow-down:before {
  content: "\e900";
}
.i-arrow-left:before {
  content: "\e901";
}
.i-arrow-right:before {
  content: "\e902";
}
.i-arrow-up:before {
  content: "\e903";
}
.i-close:before {
  content: "\e905";
}
.i-grid-2:before {
  content: "\e906";
}
.i-grid-3:before {
  content: "\e907";
}
.i-ok:before {
  content: "\e909";
}
