<template>
    <div class="d-flex justify-content-between">
        <div
            :key="quickFiltersKey"
            class="d-none d-md-block"
            >
            <quick-filters v-if="showQuickFilters" />
        </div>
        <category-filters-toggle />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import 'vue-select/dist/vue-select.css'
import CategoryFiltersToggle from './category/toggle'
import QuickFilters from './category/quick-filters/index'

export default {
    components: {
        CategoryFiltersToggle,
        QuickFilters
    },
    data() {
        return {
            showQuickFilters: false
        }
    },
    computed: {
        ...mapState('CategoryFilter', ['quickFiltersKey']),
        ...mapGetters({
            checked: 'CategoryFilter/checked',
            submit: 'CategoryFilter/submit',
            exists: 'CategoryFilter/keyExists',
            common: 'CategoryFilter/keyCommon',
            filters: 'CategoryFilter/filters'
        })
    },
    mounted() {
        window.addEventListener('resize', this.checkQuickFiltersShouldDisplay)
        this.checkQuickFiltersShouldDisplay()
    },
    beforeDeatroy() {
        window.removeEventListener('resize', this.checkQuickFiltersShouldDisplay)
    },
    methods: {
        checkQuickFiltersShouldDisplay() {
            if (window.matchMedia('(min-width: 768px)').matches) {
                this.showQuickFilters = true
            }
            else this.showQuickFilters = false
        },
        ...mapActions({
            formSubmit: 'CategoryFilter/submit'
        }),
        ...mapMutations({
            setAction: 'CategoryFilter/setAction',
            setUri: 'CategoryFilter/setUri',
            setInput: 'CategoryFilter/setInput'
        }),
        resetFilter(id) {
            this.checked[id].splice(0, this.checked[id].length)
            this.$store.commit("CategoryFilter/setFilters", this.checked)
            this.$store.dispatch("CategoryFilter/submit")
        },

        selectSubmit(id, payload) {
            this.checked[id].splice(0, this.checked[id].length)
            this.checked[id].push(payload.value)
            this.$store.commit("CategoryFilter/setFilters", this.checked)
            this.$store.dispatch("CategoryFilter/submit")
        }

    }
}
</script>
<style>
   .v-select input::placeholder{
       text-transform: uppercase;
   }
</style>
