body.scrolled-down {
  .header-wrapper {
    box-shadow: 0 2px 4px rgba($black, .15);
  }
  .header {
    &-logo {
      img {
        width: 140px;
        @include media-breakpoint-down(sm) {
          width: 115px;
        }
      }
    }
  }
}

.header {
  display: flex;
  margin: 0 auto;
  align-items: center !important;
  padding: 0;
  height: auto !important;
  color: $black;
  justify-content: center;

  &-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1001;
    background: $white;
    padding: 15px 0;
    -webkit-transition: padding 0.5s; /* Safari */
    transition: padding 0.5s, box-shadow .5s;
    border-bottom: 1px solid $gray-300;
    @include media-breakpoint-down(md) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &-logo {
    text-align: center;

    @include media-breakpoint-down(md) {
      width: 40%;
      text-align: left;
      min-height: 48px;
      display: flex;
      align-items: center;
      margin-left: 60px;
    }
    .navbar-brand {
      padding: 0 !important;
      border: 0 !important ;
      margin: 0 !important;
      height: 100%;
      display: inline-flex;
      align-items: center;

      img {
        display: block;
        width: 120px;
        margin: 0;
        transition: ease-in 300ms width;
        @include media-breakpoint-up(sm) {
          width: 140px;
        }
        @include media-breakpoint-up(lg) {
          width: 175px;
        }
      }
    }
  }

  &-nav {
    .nav-link,
    .sub-nav-link,
    .list-nav-link {
      &:hover,
      &:focus,
      &.active {
        color: theme-color("primary");
      }
    }
  }

  .header-content {
    display: flex;
    width: 100%;
    background-color: $white;
    justify-content: center;
    min-height: 48px;
    padding: 0 0;
    max-width: 1140px;
    @media (min-width: 1660px) {
      max-width: 1640px !important;
    }

    @include media-breakpoint-down(lg) {
      max-width: 960px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.5rem 0;
      min-height: 46px;
    }
    &-container {
      display: flex;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid $gray-300;

      @include media-breakpoint-up(lg) {
        border-bottom: 0 none;
      }

      @media (min-width: 1660px) {
        max-width: 1640px !important;
      }

      &__actions {
        width: 33.33%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        list-style: none;
        
        @include media-breakpoint-down(lg) {
          width: 40%;
        }
        
        @include media-breakpoint-down(sm) {
          padding-right: .5rem;
        }

        &-item {
          color: $black;

          a {
            color: $black;
            &:hover,
            &:focus,
            &.active {
              color: $black;
            }
          }

          &:not(:last-child) {
            margin-right: 0.75rem;
          }
          &:not(:first-child) {
            @include media-breakpoint-up(lg) {
              display: none;
            }
          }
        }
      }
      &__menu-button {
        width: 33.33%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
    &-top {
      color: $black;
      padding: 0.5rem $container-gutter;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      max-width: 1140px;

      
      @media (min-width: 1660px) {
        max-width: 1640px !important;
      }

      @include media-breakpoint-down(lg) {
        max-width: 960px;
      }

      @include media-breakpoint-down(md) {
        justify-content: center;
        padding: 0;
        min-height: 48px;
      }
      
      &__outer {
        width: 100%;
        border-bottom: 1px solid $black;
        @include media-breakpoint-down(md){
          border-color: #dbdbdb;
        }
      }
    }
    &-bottom {
      background-color: $white;
      @include media-breakpoint-up(lg) {
        .header-nav {
          justify-content: center;
          flex: 0 0 100%;
          display: flex;
        }
      }
      @media (min-width: 1660px) {
        max-width: 1640px !important;
      }
    }
  }

  .header-top-menu {
    list-style: none;
    padding: 0;
    margin: 0 2rem 0 0;
    display: flex;
    align-items: center;
    flex: 1;

    &__item {
      text-transform: uppercase;
      &:not(:last-child) {
        margin-right: .75rem;
      }
    }
    &__link {
      padding: .25rem .25rem .25rem 0;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .header-top-info {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 400px;
    }

    p {
      margin: 0;
      text-transform: uppercase;
    }
  }

  .header-top-actions {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;

    &__item {
      text-transform: uppercase;
      color: $black;

      &:not(:last-child) {
        margin-right: 1rem;
        @include media-breakpoint-down(md) {
          margin-right: 0.75rem
        }
      }

      a {
        color: $black;

        &:hover,
        &:focus,
        &.active {
          color: $black;
        }
        &.logout-link {
          font-size: 10px;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        i {
          font-size: 22px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      width: 40%;
      justify-content: flex-end;
      padding-right: 48px;
    }
    @include media-breakpoint-down(sm) {
      padding-right: 8px;
      margin-left: 0.5rem;
      margin-right: 0.5rem
    }
  }

  &__banderolle-bottom {
    transform: translateY(1px);
  }
}
.lg-search {
  top: 12px;
  right: 10px;
}

.basket-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .basket-badge {
    position: absolute;
    top: 10px;
    right: -4px;
    min-width: 17px;
    height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $white;
    background-color: theme-color("primary");
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 1;

    span {
      font-family: "Josefin Sans", sans-serif;
      display: inline-block;
      min-width: 10px;
      text-align: center;
      transform: translateY(10%);
      @include media-breakpoint-down(sm) {
        transform: translateY(15%)
      }
    }
  }
}

.basket-header {
  .header-logo {
    display: flex;
    justify-content: center;
    margin: 0;
  }
}
