.opinion-item {
  display: flex;
  &__image-wrapper {
    width: 50%;
    max-width: 250px;
    padding-right: 0.5rem;
    border-bottom: 0 none !important;
  }
  &__content {
    width: 50%;
    padding-left: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  &__product-name {
    margin-bottom: 0;
    font-size: rem(14px);
    line-height: 1.25;
    border-bottom: 0 none !important;
  }
  &__score {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    .stars-container {
      margin-right: 0.5rem;
    }
  }
  &__opinion-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &__more-link {
    font-weight: 500;
  }
}
