
                            @import '~@mdi/font/scss/_variables.scss';
                            @import '@scss/partials/_mixins.scss';
                            @import '~@mdi/font/scss/functions';
                            @import "~bootstrap/scss/functions";
                            @import "@scss/_functions.scss";
                            @import "@scss/_variables.scss";
                            @import "~bootstrap/scss/variables";
                            @import "~bootstrap-vue/src/variables";
                            @import "~bootstrap/scss/mixins";
                            

.VueProductCarousel {
    margin-left: -10px;
    margin-right: -10px;
//   .VueCarousel-wrapper{
//     padding-top: 15px;
//       @media(max-width: 480px){
//           margin: -15px 30px !important;
//       }
//   }
}
