.basket{
  &-header{
    border-bottom: 1px solid $black;

    @include media-breakpoint-down(md){
      padding: 0;
    }

    .header-content-container {
      border-bottom: 0 none;
    }
    @include media-breakpoint-down(xs){
      .header-logo {
        text-align: center;
        width: auto;
        margin: 0  auto !important;
      }
    }
  }
  &__view{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 167px);
  }
  &__container{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    @include media-breakpoint-up(lg){
      margin-top: -50px;
    }

    .custom-arrow-right{
      @include media-breakpoint-down(sm){
        top: 0;
      }
    }
    .plain-link{
      font-size: 12px;
      color: $black;
      font-weight: 400;
    }
    h1{
      font-size: 36px;
      @include media-breakpoint-down(sm){
        font-size: 25px;
      }
    }
    textarea.form-control{
      min-height: 100px;
    }
  }

  &__list-item{
    border-bottom: $border-width solid $gray-300;
    display: flex;
    flex-wrap: wrap;
    min-height: 145px;

    &--alternative {
      font-family: $headings-font-family;
      color: $black;

      .list-item__prices {
        s {
          font-size: 14px;
        }
      }
    }

    @include media-breakpoint-down(sm){
      padding-left: 0;
      padding-right: 0;
    }
    .btn-link-inline{
      border-width: 0 !important;
      &:hover, &:focus, &:active{
        border-color: transparent !important;
        outline: none !important;

      }
    }
    .btn-link-inline {
      &:not(.disabled) {
        cursor: pointer;
        border-width: 0 1px 1px 0;
        border-style: solid;
        border-color: transparent;

        &:hover {
          border-color: $black;
        }
        &:active {
          border-width: 1px 0 0 1px;;
        }
      }
    }
    a {
      word-break: break-word;
    }
  }
  &-gift-pack {
    padding: 0 0 1rem 0;
    color: $black;
    text-transform: uppercase;
  }
  &__info{
    color: $black;
    padding: 60px 0 60px;
    
    @include media-breakpoint-up(xl) {
      padding: 60px 0 80px;
    }

    .container {
      max-width: 1140px !important;
    }
    
    .feature-item {
      text-align: center;
      margin-bottom: 2rem;
      
      @include media-breakpoint-up(lg){
        margin-bottom: 0;
      }
      
      i{
        font-size: 50px;
      }

      strong {
        font-weight: 500;
        font-size: 16px;
      }

      p {
        margin-top: 20px;
        font-size: 12px;
      }
    }
  }
  &__buttons{
    @include media-breakpoint-down(md) {
      &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $white;
        padding: 15px;
        border-top: 1px solid $gray-300;
        z-index: 100;
        transition: none !important;

        .row {
          justify-content: flex-end !important;
        }
        .btn {
          margin: 0 !important;
        }
      }
    }
  }
  &__summary{
    h2 a{
      float: right;
      font-size: 16px;
      text-decoration: underline;
      text-transform: initial;
      letter-spacing: 1px;
      font-family: 'Roboto', sans-serif;
      margin-top: 4px;
      @include media-breakpoint-down(md){
        float: none;
        display: block;
        font-size: 12px;
        text-align: right;
        margin-left: 16.66667%;
        width: 66.66667%;
        padding: 0 10px;
      }
      @include media-breakpoint-down(xs){
        margin-left: 0;
        width: 100%;
        margin-top: 15px;
      }
      &:hover{
        text-decoration: underline !important;
        border: none !important;
      }
    }
  }

}

.list-item{
  &__photo{
    padding-right: $spacer;
    flex: 0 0 80px;
    img{
      width:80px;
    }
  }
  &__containter{
    flex: 1;
  }
  &__amount-btn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-width: 0 !important;
    font-size: 20px;
    &:hover, &:focus, &:active{
      border-color: transparent !important;
      outline: none !important;

    }
  }
  &__attr{
    margin-right: 2rem;
    margin-top: 1rem;

    @include media-breakpoint-down(sm){
      margin-right: 0;
      line-height: 16px;
      max-width: 40%;
      margin-top: 0;
      strong{
        white-space: normal;
      }
      &.attr-short{
        width: 20%;
      }
    }
    &--croped{
      display: inline-block;
      line-height: 16px;
      word-break: break-word;
      @include media-breakpoint-down(sm) {
      white-space: nowrap !important;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding-right: 10px;
        &:after {
          content: ',';
          position: absolute;
          right: 3px;
          top: 0;
          width: 5px;
        }
      }
    }
    .amount-couter{
      line-height: 14px;
      @include media-breakpoint-down(sm){
        .list-item__amount-btn{
          width: 30px;
          height: 30px;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          i{
            font-size: 18px;
          }
        }
        strong{
          display: inline-block;
          width: 15px;
          height: 30px;
          vertical-align: top;
          text-align: center;
        }
      }
    }
    &--value{
      @include media-breakpoint-down(sm){
        max-width: 100%;
        s{
          display: inline-block !important;
        }
      }
    }
    @include media-breakpoint-up(md){
      width: 70px;
    }
  }
  &__code{
    margin-top: -5px;
  }
  &__name{
    a{
      line-height: 16px;
      color: $black !important;
      letter-spacing: 0;
    }
    @include media-breakpoint-down(sm){
      max-width: 80%;
    }
  }
  &__prices{
    s{
      font-weight: normal !important;
      @include media-breakpoint-up(md){
        margin-top: -7px;
      }
    }
  }
  &__attr--value {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      s{
        display:inline-block !important;
      }
    }
  }
}
.summary-label a{
  text-transform: lowercase;
  text-decoration: underline;
  font-size: 12px;
}


.basket-discount-input-row {
  display: flex;
  flex-wrap: nowrap;

  > div {
    &:first-child {
      flex: 1;
      
    }
    &:nth-child(2) {
      min-width: 120px;
      max-width: 45%;
      margin-left: 10px;

      .btn {
        height: 45px;
        border: 1px solid $black;
        color: $black;
        background-color: $white;
        margin-top: 18px;
      }
    }
  }
}

.basket-summary {

  
  &__total{
    color: $black;
    font-size: 20px;
    text-transform: uppercase;
    font-family: $headings-font-family; 
    font-weight: 500;
  }
}