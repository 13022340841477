.alert-danger {
  color: #d40d1b !important;
}

.alert-success {
  color: #509232 !important;
}

.alert-warning {
  color: #fcae00 !important;
}

.alert-info {
  color: #0089f9 !important;
}

.alert-dark {
  color: #7a7a7a !important;
}

.alert-danger {
  background-color: #fdeded !important;
}

.alert-success {
  background-color: #f6fffa !important;
}

.alert-warning {
  background-color: #fdf3e3 !important;
}

.alert-info {
  background-color: rgb(249, 252.3012048193, 255) !important;
}

.alert-dark {
  background-color: #f2f2f2 !important;
}

.fixed-top {
  z-index: 2000 !important;
}

.params-warning .not-set {
  border-top: 1px solid red;
  color: red;
}

.prod-attr__popover-content {
  transition: 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 14px));
  padding: 0.5rem 0.75rem;
}
.prod-attr__popover-trigger:not(:hover) + .prod-attr__popover-content {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}