.modal-header {
  justify-content: center;
  .close {
    padding: 0.5rem !important;
    position: absolute;
    right: 20px;
    top: 10px;
    &:active, &:focus {
      outline:none;
    }
  }
  .modal-title{
    font-size: 1rem !important;
  }
}

.modal-footer {
  justify-content: center;
  transition: none !important;
  @include media-breakpoint-down(xs) {
    flex-wrap: wrap-reverse;
    flex-direction: column-reverse;
    button{
      margin: 0 0 10px 0 !important;
      width: 100% !important;
      &.btn-primary{
        padding: 11px !important;
      }

    }
  }

  button{
    width: 50%;
    padding: 4px !important;
    transition: none !important;
    &.btn-primary{
      padding: 11px !important;
    }

  }
}


.modal-body {
padding-top: 0 !important;
padding-bottom: 0 !important;
  @include media-breakpoint-down(xs) {
    padding: 0 1rem !important;
  }
  h1{
    @include media-breakpoint-down(xs) {
      font-size: 16px !important;
    }
  }
  .modal-basket-product{
    width: 100%;
    img{
      max-height: 200px;
    }
    h2{
      @include media-breakpoint-down(xs) {
        font-size: 1.1rem;
      }
    }
  }

}

.modal-image{
  .modal-header{
    padding:0;
    button.close{
      z-index: 1;
    }
  }
  .modal-body{
    padding: 0;

  }
}
.product-preview{
  .img-container {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='-4.313 0 28.313 24' enable-background='new -4.313 0 28.313 24' xml:space='preserve'%3E%3Cpath fill='%23D3D3D3' d='M12.15,19.05c-1.275,0-2.475-0.3-3.45-0.975c-0.975-0.675-1.875-1.5-2.475-2.55 C5.55,14.475,5.25,13.275,5.25,12c0-1.35,0.3-2.55,0.9-3.6c0.6-1.05,1.425-1.95,2.475-2.55c0.975-0.6,2.1-0.9,3.375-0.9 c1.275,0,2.4,0.3,3.45,0.975l2.175-3.6C15.825,1.275,13.95,0.75,12,0.75c-2.025,0-3.9,0.525-5.625,1.575 C4.65,3.3,3.3,4.725,2.25,6.45c-0.975,1.725-1.5,3.6-1.5,5.625c0,2.024,0.525,3.899,1.5,5.625c0.975,1.725,2.4,3.075,4.05,4.05 c1.725,0.975,3.6,1.5,5.625,1.5c2.025,0,3.9-0.525,5.625-1.5l-2.1-3.6C14.475,18.75,13.35,19.05,12.15,19.05z'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: 40%;
    background-color: $gray-100;
    background-repeat: no-repeat;
    max-width: 200px;
    @include media-breakpoint-down(xs) {
      position: relative;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }
}
.modal-category-preview{
  @include media-breakpoint-down(md) {
    .modal-header{
      padding-bottom: 1rem;
    }
    .modal-footer{
      padding-top: 1rem;
    }
    .modal-body{
      padding: 0 1rem !important;
    }
    .img-container{
      max-width: 100px;
    }
    .product-attr-container{
      margin-bottom: 0 !important;
    }
    .product-description-item{
      padding-top: 0 !important;
    }
  }
}

.cookie-modal {
  .modal-content {
    max-height: calc(100vh - 1rem);
  }
  .modal-dialog {
    margin: .5rem;
    @include media-breakpoint-up(sm) {
      margin: 1.75rem auto;
    }
    @include media-breakpoint-up(xl) {
      margin: 180px auto;
    }
  }

  .modal-header {
    justify-content: flex-start;
    img {
      margin-bottom: 2rem;
    }
    .button-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      background: transparent;
      padding: 0 !important;
      border: 0 none;
      outline: none;
      line-height: 1;
      i {
        font-size: 1rem;
      }
    }
  }
  .modal-body {
    max-height: 300px;
    overflow-y: auto;
    padding: 0 .25rem 0 0!important;
    margin: 0 2rem !important;
    overflow-y: auto; 
  }
  .modal-footer {
    justify-content: flex-start;
    .btn {
      margin-bottom: 0 !important;
    }
  }
  .modal-backdrop {
    background-color: $white;
    &.show {
      opacity: .5;
    }
  }
}
